import { useEffect, useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { chatServices } from '../../../helpers/chatService'
import { getUser } from '../../../helpers/userService'

interface NewChatModal {
  isModalOpen: boolean
  handleClose: () => void
  handleButtonAction: (e: any) => void
  link?: string
}

const NewChatModal = (props: NewChatModal) => {
  const [users, setUsers] = useState([])
  const [value, setValue] = useState<number>(0)
  const currentUser = getUser()

  useEffect(() => {
    if (currentUser.portal === 'tenant') {
      getLandlordsData()
    } else {
      getTenantsData()
    }
  }, [])

  const getTenantsData = async () => {
    const response = await chatServices.getTenantsData()
    setUsers(response.results)
  }

  const getLandlordsData = async () => {
    const response = await chatServices.getLandlordsData()
    setUsers(response.results)
  }

  const handleClose = () => {
    props.handleClose()
  }

  return (
    <Modal
      size='md'
      centered
      className='common-alert-modal custom-recurrence-modal'
      isOpen={props.isModalOpen}
      toggle={props.handleClose}
    >
      <div className='modal-header-container'>
        <div className='header-heading'>New Chat</div>
      </div>
      <ModalBody className='common-body-container p-4 d-flex flex-column justify-content-beetween align-items-stretch'>
        <div className='modal-body-content h-100'>
          <div className='user-chats'>
            {users &&
              users.length > 0 &&
              users.map((user: any, index: number) => {
                return (
                  <label
                    className={`${value === user['id'] ? 'selected' : ''} user-item`}
                    htmlFor={'user-chat-' + user['id']}
                    key={index}
                  >
                    <input
                      type='radio'
                      name='user-chat'
                      id={'user-chat-' + user['id']}
                      value={user['id']}
                      onChange={(e) => {
                        setValue(parseInt(e.target.value))
                      }}
                    />
                    <div className='user-image'>
                      {user['avatar'] ? (
                        <img className='avatar' src={user['avatar']} />
                      ) : (
                        <div className='avatar'></div>
                      )}
                    </div>
                    <span>{user['first_name_by_ll'] === "" ? `${user['first_name']} ${user['last_name']}` : `${user['first_name_by_ll']} ${user['last_name_by_ll']}`

                    }</span>
                  </label>
                )
              })}
          </div>
          <div className='modal-button d-flex justify-content-end mt-3 w-full'>
            <Button
              className='btn simple me-2'
              onClick={() => handleClose()}
            >
              {'Cancel'}
            </Button>
            <Button
              className='btn cta'
              disabled={value ? false : true}
              onClick={() => {
                props.handleButtonAction(value)
              }}
            >
              {'Create'}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default NewChatModal
