import { useEffect, useState, useRef } from 'react'
import useWebSocket from 'react-use-websocket'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

import { Container, Row, Col, Button } from 'reactstrap'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Badge } from 'primereact/badge'
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator'
import {
  setActionRows,
  setSelectedMaintenance,
  updateMaintenance,
} from '../../../store/maintenance/maintenanceSlice'

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHook'
import { setActivePageHeader } from '../../../store/auth/authSlice'
import { MaintenanceListData } from '../../../types/maintenance'
import { maintenanceService } from '../../../helpers/maintenanceService'
import { ErrorToast, SuccessToast } from '../../../helpers/toster'
import { classNames } from 'primereact/utils'
import { WS_URLS } from '../../../constants/appConstants'
import { getUser } from '../../../helpers/userService'
import DetailView from '../../../components/common/DetailView/DetailView'
import Dropdown from '../../../components/common/Dropdown/Dropdown'
import DeleteConfirmModal from '../../../components/common/DeleteConfirm/DeleteConfirmModal'
import { ref } from 'yup'


interface MaintenanceListProps {
  getMaintenanceData: () => void
  totalCount: number
}

const MaintenanceList = (props: MaintenanceListProps) => {
  const { maintenances, totalListCount } = useAppSelector((state) => state.maintenanceReducer)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsToShow, setRowsToShow] = useState(maintenances.slice(0, 8))
  const [selectedMaintenanceData, setSelectedMaintenanceData] = useState<MaintenanceListData | null>(null)
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(8)
  const [showDetailsView, setShowDetailsView] = useState(false)
  const [showDeleteConfirmModal, setShowDeleteModal] = useState(false)
  const userData = getUser()
  const [selectedId, setSelectedId] = useState<string | null>(null)

  const WS_MAINTENANCE =
    process.env.REACT_APP_WEBSOCKET + WS_URLS.MAINTENANCE_NOTIFICATIONS + userData?.id + '/'
  const { lastMessage: lastNotificationMaintenance } = useWebSocket(WS_MAINTENANCE, {
    shouldReconnect: () => true,
  })

  useEffect(() => {
    setRowsToShow(maintenances.slice(0, 8))
    setCurrentPage(1)
    setFirst(0)
    setRows(8)
  }, [maintenances])

  useEffect(() => {
    props.getMaintenanceData()
  }, [lastNotificationMaintenance])

  useEffect(() => {
    dispatch(setActivePageHeader('Maintenance List'))
  }, [])

  const onPageChange = (e: PaginatorPageChangeEvent) => {
    setFirst(e.first)
    setRows(e.rows)
    setCurrentPage(e.page + 1)
    setRowsToShow(maintenances.slice(e.first, e.rows * (e.page + 1)))
  }

  const handleRowSelect = (rowData: MaintenanceListData) => {
    dispatch(setSelectedMaintenance(rowData))
  }

  const UpdateStatus = async (rowData: MaintenanceListData) => {
    try {
      const id = rowData?.id?.toString()
      const type = rowData?.status === 'o' ? 'progress' : 'Complete'
      if (!id) {
        return
      }
      const response = await maintenanceService.updateMaintenanceTask(id, type)
      if (!response) {
        ErrorToast('Something went wrong')
        return
      }
      if (setShowDetailsView) {
        setShowDetailsView(false)
      }
      if (type === 'Complete') {
        SuccessToast(response)
        props.getMaintenanceData()
        return
      }
      dispatch(updateMaintenance('In-Progress'))
      props.getMaintenanceData()
      SuccessToast(response)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        ErrorToast(err.message || 'Error in Updating status')
      }
    }
  }

  const removeSelectedTask = async (id: string) => {
    try {
      if (id) {
        await maintenanceService.removeSelectedTask(id)
        SuccessToast('Task Removed Successfully')
      }
      props.getMaintenanceData()
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in Updating status')
        } else {
          ErrorToast('Error in Updating status')
        }
      }
    }
  }

  const processDate = (date: string) => {
    return moment(date).format('MMM D, YYYY')
  }


  /* Templates */

  const paginationTemplate = {
    layout: 'PrevPageLink PageLinks NextPageLink',
  }

  const createdDateTemplate = (rowData: MaintenanceListData) => {
    return processDate(rowData?.created)
  }

  const DescriptionTemplate = (rowData: MaintenanceListData) => {
    return (
      <span>{`${rowData.description.slice(0, 26)}${rowData.description.length > 26 ? '...' : ''
        }`}</span>
    )
  }

  const StatusTemplate = (rowData: MaintenanceListData) => {
    return (
      <Badge
        className={classNames({
          'status-badge badge-list': rowData.status_name === 'Open',
          'status-badge badge-gradient': rowData?.status_name === 'In-Progress',
          'status-badge badge-success': rowData?.status_name === 'Completed',
        })}

        severity={rowData.status_name === 'Completed' ? 'success' : null}
        value={rowData.status_name}
      />
    )
  }

  const dropDownTemplate = (rowData: MaintenanceListData) => {
    const DropdownRef = useRef<any>(null)
    const DropdownItems = [
      {
        text: 'Open Details',
        onClick: (e: any) => {
          DropdownRef.current.toggle(e)
          setShowDetailsView(true)
          setSelectedMaintenanceData(rowData)
        },
      },
      ...(rowData.status !== 'c' ? [{
        text: `Mark as ${rowData.status === 'o' ? 'In-Progress' : 'Completed'}`,
        onClick: (e: any) => {
          UpdateStatus(rowData)
          DropdownRef.current.toggle(e)
        },
      }] : []),
      {
        text: 'Remove task',
        className: 'text-danger',
        onClick: (e: any) => {
          setSelectedId(rowData.id.toString())
          setShowDeleteModal(true)
          DropdownRef.current.toggle(e)
        },
      },
    ]
    return (
      <Dropdown ref={DropdownRef} onClick={(e: any) => DropdownRef.current?.toggle(e)} content={DropdownItems} />
    )
  }

  return (
    <>
      <DetailView closeButton={showDetailsView} onCloseView={(e: boolean) => { setShowDetailsView(e) }}
        deteleButton={() => setShowDeleteModal(true)}>
        <h2>{selectedMaintenanceData?.title}</h2>
        <Container>
          <Row>
            <Col sm={6}><h4>Tenant</h4></Col>
            <Col sm={6}><p>{selectedMaintenanceData?.assignee_name}</p></Col>
          </Row>
          <Row>
            <Col sm={6}><h4>Property</h4></Col>
            <Col sm={6}><p>{selectedMaintenanceData?.property_title}</p></Col>
          </Row>
          <Row>
            <Col sm={6}><h4>Unit</h4></Col>
            <Col sm={6}><p>{selectedMaintenanceData?.assigned_unit_name}</p></Col>
          </Row>
          <Row>
            <Col sm={6}><h4>Creation date</h4></Col>
            <Col sm={6}><p>{selectedMaintenanceData?.created ? processDate(selectedMaintenanceData.created) : "N/A"}</p></Col>
          </Row>
          <Row>
            <Col sm={6}><h4>Status</h4></Col>
            <Col sm={6}><p>{selectedMaintenanceData && StatusTemplate(selectedMaintenanceData)}</p></Col>
          </Row>
          <Row>
            <Col sm={6}><h4>Description</h4></Col>
            <Col sm={6}><p>{selectedMaintenanceData?.description}</p></Col>
          </Row>
        </Container>
        {selectedMaintenanceData?.status !== 'c' && (
          <Container className='mt-2'>
            <Button onClick={() => { selectedMaintenanceData && UpdateStatus(selectedMaintenanceData) }} className='btn cta'>Mark as {selectedMaintenanceData?.status === 'o' ? "In-progress" : "Completed"}</Button>
          </Container>)}
      </DetailView>
      <div className='data-list datatable-listing pb-2 mb-3'>
        <DataTable
          editMode='row'
          value={rowsToShow}
          dataKey='id'
          tableStyle={{ minWidth: '50rem' }}
        >
          {/* <Column
            selectionMode='multiple'
            headerStyle={{ width: '3rem', textAlign: 'center' }}
          /> */}
          <Column
            sortable
            frozen
            className='fw-semibold text-black'
            field='title'
            header='Task name'
            body={(rowData: MaintenanceListData) => (
              <div
                onClick={() => {
                  handleRowSelect(rowData)
                }}
              >
                {rowData?.title}
              </div>
            )}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='assignee_name'
            header='Tenant'
          />
          <Column
            sortable
            className='fw-semibold text-black'
            header='Property'
            field='property_title'
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='assigned_unit_name'
            header='Unit'
          />
          <Column
            sortable className='fw-semibold text-black' field='created' header='Creation date' body={createdDateTemplate} />
          <Column
            sortable
            className='fw-semibold text-black'
            field='status_name'
            header='status'
            body={StatusTemplate}
          />
          <Column
            sortable
            frozen
            className='fw-semibold text-black'
            field='description'
            header='Description'
            body={DescriptionTemplate} />
          <Column headerStyle={{ width: '4rem' }} body={dropDownTemplate} />
        </DataTable>
        <div className='pagination-section mt-3 d-flex align-items-center'>
          <div className='total-records'>
            Results {first + 1}-
            {totalListCount - first < rows ? first + (totalListCount - first) : rows * currentPage}{' '}
            of {totalListCount}
          </div>
          {maintenances.length > 8 && (
            <div className='pagination-container ms-auto'>
              <Paginator
                template={paginationTemplate}
                first={first}
                rows={rows}
                totalRecords={props.totalCount}
                onPageChange={(e) => onPageChange(e)}
                className='justify-content-start'
              />
            </div>
          )}
        </div>
      </div>
      <DeleteConfirmModal
        handleReject={() => {
          setShowDeleteModal(false)
          setSelectedId(null)
        }}
        handleAccept={() => { removeSelectedTask(selectedId || ''); setShowDeleteModal(false) }}
        isModalOpen={showDeleteConfirmModal}
        header={'Delete Confirmation'}
        message={'Do you want to delete this property unit'}
      />
    </>
  )
}

export default MaintenanceList
