import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { Button, Row, Col } from 'reactstrap'
import { ROUTES } from '../../constants/routesConstant'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { saveTodotasksListData } from '../../store/todo/todoSlice'
import { todoService } from '../../helpers/todoService'
import { notificationService } from '../../helpers/notificationsService'
import ToDoTasksList from './components/ToDoTasksList'
import { CountCard } from '../../components/common/CountCard/CountCard'
import NoListingView from '../../components/common/NoListingView/NoListingView'

import noTodoImage from '../../assets/images/svgImages/no-list-todo.svg'
import searchIcon from '../../assets/images/svgImages/search_icon.svg'
import clearIcon from '../../assets/images/svgImages/clear_input_icon.svg'


const ToDoTasks = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { todotaskList } = useAppSelector((state) => state.todoReducer)
  const [filters, setFilters] = useState('')
  const [notificationSent, setNotificationSent] = useState<number>(0)
  const [EmailCredits, setEmailCredits] = useState<number>(0)
  const [SMSCredits, setSMSCredits] = useState<number>(0)

  useEffect(() => {
    dispatch(setActivePageHeader('To-Do'))
    getTotalNotifications()
    getNotificationsSent()
  }, [])

  useEffect(() => {
    if (filters) {
      const filteredData = todotaskList.filter((data) => data.name.toLowerCase().includes(filters.toLowerCase()))
      dispatch(saveTodotasksListData(filteredData, filteredData.length))
    } else {
      getToDoTasks()
    }
  }
    , [filters])

  const getToDoTasks = async () => {
    const response = await todoService.getTodoTasksList()
    if (response.results && response.results.length > 0) {
      dispatch(saveTodotasksListData(response.results, response.count))
    } else {
      dispatch(saveTodotasksListData([], 0))
    }
  }
  const getTotalNotifications = async () => {
    let totalEmails = 0
    let totalSms = 0
    const emails = await notificationService.getEmailCredits()
    if (emails) {
      totalEmails = emails.email_credits
      setEmailCredits(totalEmails)
    }
    const sms = await notificationService.getSMSCredits()
    if (sms) {
      totalSms = sms.sms_credits
      setSMSCredits(totalSms)
    }
  }

  const getNotificationsSent = async () => {
    const response = await todoService.notificationsSent()
    setNotificationSent(response.notifications_sent_current_month)
  }

  return (
    <>
      <Row className='mb-4'>
        <Col>
          <h2 className='title'>To-Do</h2>
        </Col>
        <Col className='d-flex align-items-center justify-content-end gap-2'>
          <Button
            className={'btn simple'}
            onClick={() => navigate(ROUTES.BUY_NOTIFICATIONS)}
          >
            Buy notifications
          </Button>

          <Button
            className='btn cta'
            onClick={() => navigate(ROUTES.CREATE_TODO_TASK)}
          >
            Create Task
          </Button>
        </Col>
      </Row>
      <Row className='mb-4 w-100 d-flex gap-3 row'>
        <CountCard
          heading={'Email credits left'}
          count={EmailCredits}
        />
        <CountCard
          heading={'SMS credits left'}
          count={SMSCredits}
        />
        <CountCard
          heading={`Notifications sent in ${moment().format('MMMM')}`}
          count={notificationSent}
        />
      </Row>
      <Row className='pb-4'>
        <Col md={4}>
          <div className='searchbar'>
            <div className='searchbar-container searchbar-container--bg me-2'>
              <input className='form-control' placeholder='Search by task name' value={filters} onChange={(e) => {
                setFilters(e.target.value)
              }} />
              <div className='search-icon cursor-pointer'>
                <img src={searchIcon} alt='search-icon' />
              </div>
              {filters && (
                <a
                  onClick={() => {
                    setFilters('')
                  }}
                >
                  <img src={clearIcon} className='search-clear cursor-pointer' />
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {todotaskList.length ? (
        <ToDoTasksList getTodoTasksData={getToDoTasks} />
      ) : (
        <NoListingView
          noPropText='No tasks'
          addPropText="You haven't added any tasks yet."
          image={noTodoImage}
          buttonText='Create Property'
          onClick={() => navigate(ROUTES.CREATE_TODO_TASK)}
        />
      )}
    </>
  )
}

export default ToDoTasks
