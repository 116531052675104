import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { Button, FormGroup, Col, Input, Label, Row, Card, CardHeader, CardBody } from 'reactstrap'
import { Formik, Form } from 'formik'
import { RentRequest, TenantRentPayRequest } from '../../types/tenantDetails'
import DialogBox from '../../components/common/Dialog/Index'
import { DialogData } from '../../types/setting'
import searchIcon from '../../assets/images/svgImages/search_icon.svg'
import addBankIcon from '../../assets/images/svgImages/add bank_icon.svg'

import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routesConstant'
import { setActivePageHeader } from '../../store/auth/authSlice'
import * as Yup from 'yup';
import { tenantService } from '../../helpers/tenantService'
import { ErrorToast } from '../../helpers/toster'
import { setTenantBankDetails } from '../../store/tenant/tenantSlice'

const initialValue: RentRequest = {
  rent: '',
  dueDate: '',
  paymentMethod: '',
}

const payRentValidationSchema = () =>
  Yup.object().shape({
    rent: Yup.number().required('Amount is required*'),
    dueDate: Yup.string().required('Due Date is required*'),
  })

const PayRentComponent = () => {
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setActivePageHeader('Pay Rent'))
    CheckIfBankAvailable()
  }, [])

  const { currentActivatedBankAccount } = useAppSelector((state) => state.tenantReducer)

  const dialogData = () => {
    let data = {} as DialogData
    data = {
      heading: ' ',
      subheading: 'Thank You!',
      buttonText: 'Go to dashboard',
    }
    data.heading = 'Your payment is successfully.'

    return data
  }

  const CheckIfBankAvailable = async () => {
    const response = await tenantService.getPaymentMethod()
    if (response?.data) {
      dispatch(setTenantBankDetails(response.data))
      initialValue.paymentMethod = response?.data?.id
    } else {
      ErrorToast(response)
      initialValue.paymentMethod = ''
    }
  }

  const handleSubmit = async (values: RentRequest) => {
    if (!currentActivatedBankAccount?.id || values.paymentMethod === '') {
      ErrorToast('No bank account Added')
    }
    if (values?.dueDate) {
      const formatDate = values?.dueDate.split('-')
      const newDate = `${formatDate[1]}/${formatDate[2]}/${formatDate[0]}`
      initialValue.dueDate = newDate
    }
    const payload: TenantRentPayRequest = {
      due_date: initialValue.dueDate as string,
    }

    const response = await tenantService.tenantPayRent(payload);
    if (!response?.success) {
      ErrorToast(response?.msg)
      setVisible(false)
    }
    if (response?.detail) {
      ErrorToast(response?.detail)
    }
    if (response?.id) {
      setVisible(true)
    }

  }

  const setPageRendering = () => {
    navigate(ROUTES.TENANT_SCHEDULE_PAYMENT)
  }

  const addBank = async () => {
    navigate(ROUTES.TENANT_ADDBANK_DETAILS)
  }

  return (
    <>
      {currentActivatedBankAccount?.id ? (
        <>
          <DialogBox
            open={visible}
            setOpen={(status: boolean) => setVisible(status)}
            isSuccess={true}
            data={dialogData()}
          ></DialogBox>

          <Row className='pb-4'>
            <Col md={8}>
              <Col md={6}>
                {/* <div className='searchbar-container me-4'>
                  <input className='form-control' placeholder='Search here...' />
                  <div className='search-icon cursor-pointer'>
                    <img src={searchIcon} alt='search-icon' />
                  </div>
                </div> */}
              </Col>
            </Col>
            <Col md={4}>
              <div className='d-flex justify-content-end'>
                <Button
                  type='button'
                  className='headerButton btn submit-btn'
                  onClick={setPageRendering}
                >
                  {'Schedule Payment'}
                </Button>
              </div>
            </Col>
          </Row>
          <Card className='form-card'>
            <CardHeader>Transfer Payment</CardHeader>
            <CardBody>
              <Formik
                initialValues={initialValue}
                onSubmit={(value: RentRequest) => {
                  handleSubmit(value)
                }}
                validationSchema={() => payRentValidationSchema()}
              >
                {({ errors, touched, values, handleChange, handleBlur, dirty, isValid }) => (
                  <Form>
                    <Row className='mt-5'>
                      <Col md={6}>
                        <FormGroup className='mb-4'>
                          <Label className='formLabel' for='rent'>
                            Amount
                          </Label>
                          <Input
                            id='rent'
                            name='rent'
                            type='number'
                            placeholder='Enter Amount'
                            value={values?.rent}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                          {touched.rent && errors.rent && (
                            <span className='error-text text-danger'>{errors.rent}</span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <>
                          <FormGroup className='mb-4'>
                            <Label className='formLabel' for='dueDate'>
                              Date
                            </Label>
                            <Input
                              id='dueDate'
                              type='date'
                              name='dueDate'
                              placeholder='MM/DD/YYYY'
                              value={values?.dueDate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                            {touched.dueDate && errors.dueDate && (
                              <span className='error-text text-danger'>{errors.dueDate}</span>
                            )}
                          </FormGroup>
                        </>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={8}>
                        <FormGroup className='mb-4'>
                          <Label className='formLabel' for='paymentMethod'>
                            Payment method
                          </Label>
                          <Input
                            id='paymentMethod'
                            name='paymentMethod'
                            type='text'
                            value={values.paymentMethod}
                            onChange={handleChange}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <Col md={6}>
                          <div className='addBankbuttonStyling'>
                            <Button className='addBankDetails submit-btn'>
                              <span>
                                {currentActivatedBankAccount?.id ? 'Edit Bank' : ' Add Details'}{' '}
                              </span>
                            </Button>
                          </div>
                        </Col>
                      </Col>
                    </Row>

                    <Row className='mt-5'>
                      <Col md={6}></Col>
                      <Col md={6}>
                        <div className='addBankButton d-flex justify-content-end'>
                          <Button
                            type='button'
                            className='btn submit-btn'
                            disabled={!dirty || !isValid}
                            onClick={() => handleSubmit(values)}
                          >
                            {'Pay'}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          <div className='p-4'>
            <Row className='pb-4'>
              <Col md={4}>
                {/* <div className='searchbar-container me-4'>
                  <input className='form-control' placeholder='Search here...' />
                  <div className='search-icon cursor-pointer'>
                    <img src={searchIcon} alt='search-icon' />
                  </div>
                </div> */}
              </Col>
              <Col md={8} className='d-flex align-items-center justify-content-end'>
                <Button className='btn add-new-item-btn ms-4'>Schedule Payment</Button>
              </Col>
            </Row>
          </div>
          <div className='paymentLanding'>
            <div className='row w-100 justify-content-center align-items-center'>
              <div className='tenant-add-bank col-md-12 col-lg-6 d-flex justify-content-center align-items-center'>
                <div className='text-center'>
                  <img src={addBankIcon} alt='Add bank' />
                  <p className='paymentLanding-prop mb-1'>{'Please Add Bank Details '}</p>
                  <div className='d-flex justify-content-center'>
                    <p className='paymentLanding-add-bank-note'>{'Note that the fee for adding one bank is $24 per year.'}</p>
                  </div>
                  <div className='xyzz'>
                    <Button className='PaymentAddbutton' onClick={addBank}>
                      <span>Add Bank</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default PayRentComponent
