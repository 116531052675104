import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Button, FormGroup, Label } from 'reactstrap'
import { FloatingInputField } from '../common/FloatingInputField/FloatingInputField'
import eyeIcon from '../../assets/images/svgImages/eye_icon.svg'
import Checkbox from '../common/Checkbox/Checkbox'
import { registerFormStates } from '../../pages/Signup'
import closeEyeIcon from '../../assets/images/svgImages/close_eye_icon.svg'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { tenantService } from '../../helpers/tenantService'
import { ROUTES } from '../../constants/routesConstant'
import { ErrorToast, SuccessToast } from '../../helpers/toster'
import { TenantRegistrationRequest } from '../../types/tenantDetails'

export interface signupFormProps {
  handleSubmit: (registerFormStates: registerFormStates) => void
}

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  password: '',
  retypePassword: '',
}

const signUpValidationSchema = () =>
  Yup.object().shape({
    first_name: Yup.string()
      .max(15, 'Must be 15 characters or less')
      .min(3, 'Must be 3 characters')
      .matches(/[A-Za-z]+/g, 'First Name must contain character only')
      .required('First Name is Required*'),
    last_name: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .min(3, 'Must be 3 characters')
      .matches(/[A-Za-z]+/g, 'Last Name must contain character only')
      .required('Last Name is Required*'),
    // email: Yup.string().required('Email is required*').email('invalid email'),
    // phone: Yup.string().required('Phone Number is required*'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 charaters')
      .matches(
        /^(?=.*[A-Za-zZñÑáéíóúÁÉÍÓÚ])(?=.*\d)(?=.*[@$!%*#?&^()])[A-Za-zZñÑáéíóúÁÉÍÓÚ\d@$!%*#?&^()]{8,}$/,
        'Password must contain at least one letter, one number and one special character',
      )
      .required('Password is required*'),
  })

const SignUpForm = (props: signupFormProps) => {
  useEffect(() => {
    checkURL()
  }, [])
  const location = useLocation()
  const navigate = useNavigate()
  const [isTenant, setIsTenant] = useState(false)

  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)

  const [agreeTerms, setAgreeTerms] = useState(false)

  const searchParams = new URLSearchParams(window.location.search)
  const firstName = searchParams.get('first_name') || ''
  const lastName = searchParams.get('last_name') || ''
  const handleSubmit = async (formData: registerFormStates) => {
    if (isTenant) {
      const uidb64 = searchParams.get('uidb64')
      const token = searchParams.get('token')

      const payload: TenantRegistrationRequest = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        password: formData.password,
        confirm_password: formData.retypePassword,
        token: token as string,
        uidb64: uidb64 as string,
      }
      const response = await tenantService.registerTenant(payload)
      if (response?.email) {
        SuccessToast('User registered successfully. Please login')
        navigate(ROUTES.LOGIN)
      } else {
        ErrorToast(response?.response?.data?.password.join(', '))
      }
    } else {
      props.handleSubmit(formData)
    }
  }

  const checkURL = async () => {
    if (location.pathname.includes('tenant')) {
      setIsTenant(true)
      initialValues.first_name = firstName
      initialValues.last_name = lastName
    }
  }

  return (
    <div className='signup-form'>
      {isTenant && <p className='info-text'>You are attempting to register a tenant account</p>}
      {!isTenant && <p className='info-text'>You are creating a landlord account</p>}

      {!isTenant && (
        <>
          <h3 className='form-heading mt-5'>
            Sign Up & Set Up Your First Unit Completely{' '}
            <Link to='/' className='app-links'>
              Free!
            </Link>
          </h3>
          <div className='informative-content'>
            New User?{' '}
            <Link className='link-content' to='signup'>
              Create a HappyPropy Online account
            </Link>
          </div>
          <div className='informative-content'>
            <Link to='/' className='app-links'>
              Already have an account?
            </Link>
          </div>
        </>
      )}
      {isTenant && (
        <>
          <h3 className='form-heading mt-3'>
            Welcome, {firstName} {lastName}
          </h3>
          <div className='informative-content mb-5'>Please create a password and sign in</div>
        </>
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={(values: registerFormStates) => {
          handleSubmit(values)
        }}
        validationSchema={() => signUpValidationSchema()}
      >
        {({ errors, values, touched, handleBlur, handleChange, setFieldValue, isValid }) => (
          <Form>
            <div className='row'>
              {!isTenant && (
                <FormGroup className='col-12 my-4'>
                  <FloatingInputField
                    name='email'
                    label={'Email addess'}
                    type={'text'}
                    onChange={handleChange}
                    value={values.email}
                    isRequired={true}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <span className='error-text text-danger'>{errors.email}</span>
                  )}
                </FormGroup>
              )}
            </div>
            {!isTenant && (
              <div className='row'>
                <FormGroup className='col-md-6 mb-4'>
                  <FloatingInputField
                    name='first_name'
                    label={'First name'}
                    type={'text'}
                    onChange={handleChange}
                    value={isTenant ? firstName : values.first_name}
                    isRequired={true}
                    onBlur={handleBlur}
                    disabled={isTenant}
                  />
                  {touched.first_name && errors.first_name && (
                    <span className='error-text text-danger'>{errors.first_name}</span>
                  )}
                </FormGroup>

                <FormGroup className='col-md-6 mb-4'>
                  <FloatingInputField
                    name='last_name'
                    label={'Last name'}
                    type={'text'}
                    onChange={handleChange}
                    value={isTenant ? lastName : values.last_name}
                    isRequired={true}
                    onBlur={handleBlur}
                    disabled={isTenant}
                  />
                  {touched.last_name && errors.last_name && (
                    <span className='error-text text-danger'>{errors.last_name}</span>
                  )}
                </FormGroup>
              </div>
            )}

            {!isTenant && (
              <div className='row'>
                <div
                  className={
                    !!values.phone === false
                      ? 'phone-input position-relative col-12 mb-4'
                      : 'phone-input position-relative mb-4 filled col-12'
                  }
                >
                  <PhoneInput
                    value={values.phone}
                    defaultCountry='US'
                    smartCaret={true}
                    onChange={(e: any) => setFieldValue('phone', e)}
                    required
                    onBlur={handleBlur}
                    name='phone'
                    maxLength={14}
                  />
                  {touched.phone && errors.phone && (
                    <span className='error-text text-danger'>{errors.phone}</span>
                  )}
                  {values.phone && !errors.phone && isValidPhoneNumber(values.phone) === false && (
                    <span className='error-text text-danger'>Invalid Phone Number</span>
                  )}
                  <Label className='input-label'>Phone number</Label>
                  <div className='form-input-text'>
                    Standard call, messaging or data rates may apply.
                  </div>
                </div>
              </div>
            )}

            <div className='row'>
              <FormGroup className='col-12 mb-4 position-relative'>
                <FloatingInputField
                  name='password'
                  label={'Password'}
                  type={isShowPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  value={values.password}
                  isRequired={true}
                  onBlur={handleBlur}
                />
                <img
                  alt='eye-icon'
                  className='img-icon'
                  src={isShowPassword ? eyeIcon : closeEyeIcon}
                  onClick={() => setIsShowPassword((prevProp) => !prevProp)}
                />
                {touched.password && errors.password && (
                  <span className='error-text text-danger'>{errors.password}</span>
                )}
              </FormGroup>
            </div>

            {isTenant && (
              <div className='row'>
                <FormGroup className='col-12 mb-4 position-relative'>
                  <FloatingInputField
                    name='retypePassword'
                    label={'Repeat password'}
                    type={isShowConfirmPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    value={values.retypePassword}
                    isRequired={true}
                    onBlur={handleBlur}
                  />
                  <img
                    alt='eye-icon'
                    className='img-icon'
                    src={isShowConfirmPassword ? eyeIcon : closeEyeIcon}
                    onClick={() => setIsShowConfirmPassword((prevProp) => !prevProp)}
                  />
                  {touched.password && errors.password && (
                    <span className='error-text text-danger'>{errors.password}</span>
                  )}
                </FormGroup>
              </div>
            )}

            <div className='d-flex align-items-center justify-content-between'>
              <Button
                className='btn cta'
                block
                type='submit'
                disabled={!agreeTerms || !isValid}
              >
                <span>{isTenant ? 'Sign in' : 'Start Free Trial'}</span>
              </Button>
            </div>
            <div className='mt-3 mb-1'>
              <Checkbox
                checked={agreeTerms}
                onChange={() => setAgreeTerms((prevProp) => !prevProp)}
              />
            </div>
            <div className='divider'></div>
            <div className='secutity-info'>
              This site is protected by reCAPTCHA and the Google{' '}
              <Link to='/' className='app-links'>
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link to='/' className='app-links'>
                Terms of Service
              </Link>{' '}
              apply.
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SignUpForm
