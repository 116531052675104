import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import CompanyInfo from '../../components/CompanyInfo/CompanyInfo';
import SignUpForm from '../../components/SignUpForm';
import { API_URLS } from '../../constants/appConstants';
import { ROUTES } from '../../constants/routesConstant';
import API from '../../helpers/api';
import { ErrorToast, SuccessToast } from '../../helpers/toster';

export interface registerFormStates {
    email: string; 
    first_name: string; 
    last_name: string;
    password: string; 
    phone: string;
    retypePassword: string;
}

const Signup = () => {
    const navigate = useNavigate();
    const handleSubmit = async (formValues: registerFormStates) => {
        try {
            const response = await API.post(API_URLS.REGISTER, formValues);
            if(response.status === 201 || response.status === 200) {
                navigate(ROUTES.LOGIN);
                SuccessToast("Signup Successfully. Please verify your email to sign In");
            } else {
                ErrorToast("Signup failed. Something went wrong");
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if(error.response?.data.email) {
                    ErrorToast(error.response?.data.email[0]);
                    return false;
                } 
                if(error.response?.data.password) {
                    ErrorToast(error.response?.data.password[0]);
                    return false;
                }
            }
            ErrorToast("Invalid request");
        }
    }

    return (
        <div className="signup-wrapper d-flex justify-content-center align-items-center container-fluid">
            <Row className="justify-content-center align-items-center h-100">
                <Col lg={6} className="p-5">
                    <div className="form-card">
                        <div className="signup-form-container">
                            <SignUpForm handleSubmit={handleSubmit} />
                        </div>    
                    </div>
                </Col>
                <Col lg={6} className="p-5">
                    <CompanyInfo />
                </Col>
            </Row>    
        </div>
    )
}

export default Signup;