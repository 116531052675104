import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import NoListingView from '../../components/common/NoListingView/NoListingView'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { ROUTES } from '../../constants/routesConstant'
import { propertiesService } from '../../helpers/propertiesService'
import { saveUnitsListData } from '../../store/properties/propertiesSlice'
import { PropertiesTypes, PropertyData, UnitsListData } from '../../types/properties'
import searchIcon from '../../assets/images/svgImages/Search.svg'
import noListUnit from '../../assets/images/svgImages/no-list-unit.svg'
import clearIcon from '../../assets/images/svgImages/clear_input_icon.svg'

import UnitsList from './components/UnitsList'

const Units = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { unitsList } = useAppSelector((state) => state.propertiesReducer)
  const [properties, setProperties] = useState<PropertyData[]>()
  const [types, setTypes] = useState<PropertiesTypes[]>()
  const [checkedProperties, setCheckedProperties] = useState<PropertyData[]>([])
  const [checkedTypes, setCheckedTypes] = useState<PropertiesTypes[]>([])
  const [filters, setFilters] = useState<string>("")

  useEffect(() => {
    getTypes()
    if (unitsList.length > 0) {
      dispatch(setActivePageHeader('Units List'))
    } else {
      dispatch(setActivePageHeader('Units'))
    }
    getAllUnitsData()
  }, [])


  useEffect(() => {
    if (filters) {
      const filteredData = unitsList.filter((data) => data.unit_name.toLowerCase().includes(filters.toLowerCase()))
      dispatch(saveUnitsListData(filteredData, filteredData.length))
    } else {
      getAllUnitsData()

    }
  }
    , [filters])

  const getAllUnitsData = async () => {
    const response = await propertiesService.getAllPropertyUnits()
    if (response.results && response.results.length > 0) {
      dispatch(saveUnitsListData(response.results, response.count))
      dispatch(setActivePageHeader('Units'))
      if (!properties) {
        const propertyData: PropertyData[] = []
        response.results.map((data: UnitsListData) => {
          if (data.property) {
            const filterUnit = propertyData.filter((x) => x.id == Number(data.property))
            if (!filterUnit.length) {
              propertyData.push({
                id: Number(data.property),
                title: data.property_title,
              })
            }
          }
        })
        setProperties(propertyData)
      }
    } else {
      dispatch(saveUnitsListData([], 0))
      dispatch(setActivePageHeader('Units'))
    }
  }

  const getTypes = async () => {
    const response = await propertiesService.getPropertyTypes()
    if (response.results && response.results.length > 0) {
      setTypes(response.results)
    }
  }

  const handleCheckboxChange = (data: PropertyData) => {
    const isChecked = checkedProperties.some((checkedCheckbox) => checkedCheckbox.id === data.id)
    if (isChecked) {
      setCheckedProperties(
        checkedProperties.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      )
    } else {
      setCheckedProperties(checkedProperties.concat(data))
    }
  }

  const handleCheckboxTypeChange = (data: PropertiesTypes) => {
    const isChecked = checkedTypes.some((checkedCheckbox) => checkedCheckbox.id === data.id)
    if (isChecked) {
      setCheckedTypes(checkedTypes.filter((checkedCheckbox) => checkedCheckbox.id !== data.id))
    } else {
      setCheckedTypes(checkedTypes.concat(data))
    }
  }

  return (
    <>
      <Row className='pb-5'>
        <Col>
          <h2 className='title'>Units</h2>
        </Col>
        <Col className='d-flex align-items-center justify-content-end'>
          <Button className='btn simple me-2' onClick={() => navigate(ROUTES.SUBSCRIPTIONS)}>
            Buy unit connect
          </Button>
          <Button className='btn cta' onClick={() => navigate(ROUTES.ADD_UNITS)}>
            New Unit
          </Button>
        </Col>
      </Row>
      <Row className='pb-5'>
        <Col md={4}>
          <div className='searchbar'>
            <div className='searchbar-container searchbar-container--bg me-2'>
              <input className='form-control' placeholder='Search by unit name' value={filters} onChange={(e) => {
                setFilters(e.target.value)
              }} />
              <div className='search-icon cursor-pointer'>
                <img src={searchIcon} alt='search-icon' />
              </div>
              {filters && (
                <a
                  onClick={() => {
                    setFilters('')
                  }}
                >
                  <img src={clearIcon} className='search-clear cursor-pointer' />
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {unitsList?.length ? (
        <UnitsList getUnitsData={getAllUnitsData} />
      ) : (
        <NoListingView
          image={noListUnit}
          noPropText='No units'
          addPropText="You haven' t added any units yet."
        />
      )}
    </>
  )
}

export default Units
