import { Button, List, ListInlineItem } from "reactstrap";
import locationIcon from '../../../../assets/images/location-icon.png'
import { useEffect, useState } from "react";
import { settingService } from "../../../../helpers/settingService";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHook";
import { saveSubscriptionList } from '../../../../store/setting/settingSlice'
import { setActivePageHeader } from "../../../../store/auth/authSlice";
import { findDayNth, getMonthName } from "../../../../helpers/utils";
import { propertiesService } from "../../../../helpers/propertiesService";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routesConstant";

const SubscriptionDetail = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [unitYearlyPrice, setUnitYearlyPrice] = useState(0);
  const [unitMonthlyPrice, setUnitMonthlyPrice] = useState(0)
  const { subscriptionList } = useAppSelector((state) => state.settingReducer);

  useEffect(()=>{
    getSubscriptionList()
    dispatch(setActivePageHeader('Settings'))
    getUnitPriceData()
  },[])

  const cancelSubscription = async(id: number | null) =>{
    return await settingService.cancelSubscription(id?.toString() || '');
  }


  const getSubscriptionList = async () =>{
    const response = await settingService.getSubscriptionList()
    if(response){
      dispatch(saveSubscriptionList(response))
    }
  }

  const handleCancel = async () => {
    const id = subscriptionList?.id || null;
    await cancelSubscription(id)
    getSubscriptionList();
  }

  const getUnitPriceData = async () => {
    const response = await propertiesService.getUnitPrice();
    setUnitYearlyPrice(response?.yearly_amount);
    setUnitMonthlyPrice(response?.monthly_amount);
  }

  return (
    <>
      <div className="mt-4">
        { subscriptionList !== null ? (
            <div className="mb-5">
              <p className='detail pt-2'>Subscription Information</p>
              <List type='inline' className="d-flex align-items-center">
                <ListInlineItem>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img src={locationIcon} alt='' />
                    <div className='detail-item'>
                      <p>
                        Total Connects <span>| {subscriptionList?.paid_connects ? subscriptionList?.paid_connects : 'N/A'}</span>
                      </p>
                    </div>
                  </div>
                </ListInlineItem>
            
                {/* <ListInlineItem>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img src={locationIcon} alt='' />
                    <div className='detail-item'>
                      <p>
                        Free Connects <span>| 1</span>
                      </p>
                    </div>
                  </div>
                </ListInlineItem> */}
            
                <ListInlineItem>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img src={locationIcon} alt='' />
                    <div className='detail-item'>
                      <p>
                        Paid Connects
                        <span>
                          |{' '}
                          {subscriptionList?.paid_connects ? subscriptionList?.paid_connects : 'N/A'}
                        </span>
                      </p>
                    </div>
                  </div>
                </ListInlineItem>
                <ListInlineItem>
                  <div className='detail-item'>
                    <Button onClick={() =>{
                      navigate(ROUTES.SUBSCRIPTIONS)
                    }} className="buttonStyle"> Buy Unit Connects</Button>
                  </div>
                </ListInlineItem>
              </List> 
          
              <p className='detail mt-4'>Subscription Payments</p>
              <List type='inline'>
                <ListInlineItem>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img src={locationIcon} alt='' />
                    <div className='detail-item'>
                      <p>
                        Upcoming payments <span>| { subscriptionList?.paid_connects ? '$' + (subscriptionList?.paid_connects * (subscriptionList?.subscription_plan === 'Y' ? unitYearlyPrice : unitMonthlyPrice)) * (subscriptionList?.subscription_plan === 'Y' ? 12 : 1) : 'N/A'}</span>
                      </p>
                    </div>
                  </div>
                </ListInlineItem>
              
                <ListInlineItem>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img src={locationIcon} alt='' />
                    <div className='detail-item'>
                      <p>
                      Payment Date <span>| {subscriptionList?.payment_day ? subscriptionList?.payment_day : 'N/A'}{subscriptionList?.payment_day && findDayNth(subscriptionList?.payment_day || 1)} {subscriptionList?.payment_month && getMonthName(subscriptionList?.payment_month || 1)}</span>
                      </p>
                    </div>
                  </div>
                </ListInlineItem>
              
                <ListInlineItem>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img src={locationIcon} alt='' />
                    <div className='detail-item'>
                      <p>
                      Subscription Plan <span>| {subscriptionList?.subscription_plan_name ? subscriptionList?.subscription_plan_name : 'N/A'}</span>
                      </p>
                    </div>
                  </div>
                </ListInlineItem>
              </List>

              <p className='detail mt-4'>Connects Expiry</p>
              <List type='inline'>
                <ListInlineItem>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img src={locationIcon} alt='' />
                    <div className='detail-item'>
                      <p>
                        Upcoming Expiring Connects  <span>| {subscriptionList?.paid_connects ? subscriptionList?.paid_connects : 'N/A'}</span>
                      </p>
                    </div>
                  </div>
                </ListInlineItem>
              
                <ListInlineItem>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img src={locationIcon} alt='' />
                    <div className='detail-item'>
                      <p>
                      Expiry Date <span>| {subscriptionList?.payment_day ? subscriptionList?.payment_day : 'N/A'}{subscriptionList?.payment_day && findDayNth(subscriptionList?.payment_day || 1)} {subscriptionList?.payment_month && getMonthName(subscriptionList?.payment_month || 1)}</span>
                      </p>
                    </div>
                  </div>
                </ListInlineItem>
              </List>
            </div>
          ) : 'No Subscriptions Buyed' 
        }
        
        <div className="cancelButtonStyling">
          { (subscriptionList?.id && subscriptionList?.active) &&
            <Button  className="cancelSubscription"  
              onClick={ handleCancel }
            >Cancel Subscription</Button>
          }
        </div>
      </div>
    </>
  )
}
export default SubscriptionDetail;