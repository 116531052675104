import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { AddCard, AddCardRequest } from '../../../../types/setting'
import { loadStripe } from '@stripe/stripe-js'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import { ErrorToast } from '../../../../helpers/toster'
import { useAppDispatch } from '../../../../hooks/storeHook'
import { setActivePageHeader } from '../../../../store/auth/authSlice'
import { settingService } from '../../../../helpers/settingService'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#84818A',
      color: 'black',
      fontWeight: 400,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#84818A' },
      '::placeholder': { color: '#84818A' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: 'black',
    },
  },
  showIcon: true,
}

interface NewCardProps {
  handleBackButton: () => void
  getCreditCardList: () => void
}

const initialValue: AddCard = {
  name: '',
  cardNumber: '',
  expirationDate: '',
  cvv: 123,
}
const stripe = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY ? process.env.REACT_APP_STRIPE_API_KEY : '',
)

const AddNewCard = (props: NewCardProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()

  const handleSubmit = () => {
    createToken()
  }

  useEffect(() => {
    dispatch(setActivePageHeader('Settings'))
  }, [])

  const createToken = async () => {
    const card = elements?.getElement(CardNumberElement)
    if (card) {
      try {
        const result = await stripe?.createToken(card)
        if (result?.error) {
          ErrorToast(result?.error?.message || 'Error in payment')
        } else {
          const payload = {
            token: result?.token?.id as string,
          }
          addCreditCard(payload)
        }
      } catch (err) {
        ErrorToast('Error in payment')
      }
    }
  }

  const addCreditCard = async (payload: AddCardRequest) => {
    const result = await settingService.addCreditCard(payload)
    if (!result?.success) {
      ErrorToast(result?.msg)
    } else {
      props?.getCreditCardList()
      props.handleBackButton()
    }
  }

  return (
    <div className='mt-4'>
      <Formik
        initialValues={initialValue}
        onSubmit={() => {
          handleSubmit()
        }}
      >
        <Form>
          <FormGroup>
            <Label className='formLabel pb-0'>Enter Your New Card Details</Label>
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup className='mb-4'>
                <Label className='formLabel' for='cardNumber'>
                  Card Number
                </Label>
                <CardNumberElement className='form-control' options={CARD_ELEMENT_OPTIONS} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className='mb-4'>
                <Label className='formLabel' for='expirationDate'>
                  Expiration Date
                </Label>
                <CardExpiryElement className='form-control' />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <FormGroup className='mb-4 position-relative'>
                <Label className='formLabel' for='cvv'>
                  CVV
                </Label>
                <CardCvcElement className='form-control' />
                {/* <img alt="eye-icon" className="cvvImageIcon" src={ isShowCvv ? eyeIcon : closeEyeIcon } onClick={() => setIsShowCvv(prevProp => !prevProp)} /> */}
              </FormGroup>
            </Col>
          </Row>

          {/* <div className='paymentCardSaveButton'>
            <Button type='submit' className='btn submit-btn'>Save</Button>
          </div> */}
          <div className='addBankButton d-flex justify-content-end'>
            <Button type='button' className='btn submit-btn me-3' onClick={props.handleBackButton}>
              Back
            </Button>
            <Button type='submit' className='btn payout-submit-btn submit-btn'>
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

const NewCard = (props: NewCardProps) => {
  return (
    <Elements stripe={stripe}>
      <AddNewCard
        handleBackButton={props.handleBackButton}
        getCreditCardList={props.getCreditCardList}
      />
    </Elements>
  )
}

export default NewCard
