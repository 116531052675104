import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { NavItem, Button } from 'reactstrap'
import { Sidebar } from 'primereact/sidebar';
import { logout } from '../../store/auth/authSlice'
import { useAppDispatch } from '../../hooks/storeHook'
import { ROUTES } from '../../constants/routesConstant'
import { SidebarMenus, TenantSideMenu } from '../../constants/appConstants'
import { clearStorage, getMenuPreference, setMenuPreference } from '../../helpers/userService'

import LogoImagotype from '../../assets/images/svgImages/logo.svg'
import LogoIsotype from '../../assets/images/svgImages/LogoIsotype.svg'
import CollapseIcon from '../../assets/images/svgImages/collapse.svg'
import CloseSidebar from '../../assets/images/svgImages/close_sidebar.svg'
import logoutIcon from '../../assets/images/svgImages/Logout.svg'


const SidebarLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isTenant, setIsTenant] = useState(false)
  const [userType, setUserType] = useState('Landlord')
  const menuPreference = getMenuPreference()
  const [isCollapsed, setIsCollapsed] = useState(
    menuPreference ? (menuPreference === 'true' ? true : false) : false,
  )
  const [menuOptions, setMenuOptions] = useState<any>()
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  useEffect(() => {

    CheckIsTenant()
    if (!isTenant && userType !== 'tenant') {
      setMenuOptions(SidebarMenus.map((menuData, index) => {
        return (
          <NavItem
            key={index}
            active={location.pathname.includes(menuData.path) ? true : false}
          >
            <Link
              to={menuData.path}
              className='nav-link align-middle d-flex align-items-center'
            >
              <img
                src={menuData.icon}
                className={location.pathname.includes(menuData.path) ? 'active' : ''}
              />
              <span className='ms-3 nav-link-text'>{menuData.name}</span>
            </Link>
          </NavItem>
        )
      }))
    } else {
      setMenuOptions(TenantSideMenu.map((menuData, index) => {
        return (
          <NavItem
            key={index}
            active={location.pathname.includes(menuData.path) ? true : false}
            className='py-1'
          >
            <Link
              to={menuData.path}
              className='nav-link align-middle d-flex align-items-center'
              onClick={() => setMobileMenuVisible(false)}
            >
              <img src={menuData.icon} />
              <div className='ms-3 nav-link-text'>{menuData.name}</div>
            </Link>
          </NavItem>
        )
      }))
    }
  }, [location])

  const handleLogout = () => {
    dispatch(logout())
    clearStorage()
    navigate(ROUTES.LOGIN)
  }

  const CheckIsTenant = async () => {
    const user = JSON.parse(localStorage.getItem('pmanagement.user') as string)
    setUserType(user.portal as string)
    if (user.portal === 'tenant') {
      setIsTenant(true)
    }
  }

  const handleMenuPreference = (value: boolean) => {
    setMenuPreference(value)
  }

  return (
    <>
      {/* Desktop aside menu */}
      <div
        className={`sidebar ${isCollapsed ? 'collapsed' : 'not-collapsed'
          }`}>
        <div className='header'>
          <Link to='/' className='company-logo d-flex align-items-center'>
            <img src={isCollapsed ? LogoIsotype : LogoImagotype} alt='company-logo' />
          </Link>

          <Button
            className='btn flat collapse-button'
            onClick={() => {
              handleMenuPreference(!isCollapsed)
              setIsCollapsed(!isCollapsed)
            }}
          >
            <img src={CollapseIcon} />
          </Button>
        </div>
        <ul
          className='nav nav-pills mb-auto'
          id='menu'
        >
          {menuOptions}
        </ul>
        <ul className='nav nav-pills w-100'>
          <NavItem>
            <div onClick={handleLogout} className='nav-link align-middle d-flex align-items-center'>
              <img src={logoutIcon} />
              <span className='ms-3 nav-link-text'>Logout</span>
            </div>
          </NavItem>
        </ul>
      </div>

      {/* Mobile aside menu */}

      <div className="mobile-sidebar">
        <div className="mobile-sidebar-button">
          <Button className='btn flat' onClick={() => setMobileMenuVisible(true)}><img src={CollapseIcon} /></Button>
        </div>
        <Sidebar className='sidebar' visible={mobileMenuVisible} onHide={() => setMobileMenuVisible(false)} content={({ hide }) => (
          <>
            <div className='header'>
              <Link to='/' className='company-logo d-flex align-items-center'>
                <img src={LogoImagotype} alt='company-logo' />
              </Link>

              <Button
                className='btn flat'
                onClick={
                  (e: any) => hide(e)
                }
              >
                <img src={CloseSidebar} />
              </Button>
            </div>
            <ul
              className='nav nav-pills mb-auto'
              id='menu'
            >
              {menuOptions}
            </ul>
            <ul className='nav nav-pills w-100'>
              <NavItem>
                <div onClick={handleLogout} className='nav-link align-middle d-flex align-items-center'>
                  <img src={logoutIcon} />
                  <span className='ms-3 nav-link-text'>Logout</span>
                </div>
              </NavItem>
            </ul>
          </>
        )} />
      </div>
    </>
  )

}

export default SidebarLayout
