import { Label, Input } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';

export interface inputFieldProps {
    value?: string;
    label?: string;
    name?: string;
    placeholder?: string;
    type?: InputType;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleOnKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    maxLength?: number;
    minLength?: number;
    isRequired?: boolean;
    disabled?: boolean;
}

export const FloatingInputField = (props: inputFieldProps) => {
    const { value = '', label, name, type, onChange, handleOnKeyUp, onBlur, maxLength, minLength, isRequired = false, disabled } = props;
    return (
        <div className='floating-input-container'>
            <Input
                className={ value !== '' ? 'form-control filled' : 'form-control' }
                type={type}
                value={value}
                name={name}
                onChange={onChange}
                onKeyUp={handleOnKeyUp}
                maxLength={maxLength}
                minLength={minLength}
                required={isRequired}
                onBlur={onBlur}
                placeholder='floating-input'
                disabled={disabled}
            />
                <Label className='input-label'>{label}</Label>
        </div>
    )
}