import { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'

import { Col, Row } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { saveMaintenance } from '../../store/maintenance/maintenanceSlice'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { maintenanceService } from '../../helpers/maintenanceService'
import { ErrorToast } from '../../helpers/toster'
import { MaintenanceListData } from '../../types/maintenance'
import NoListingView from '../../components/common/NoListingView/NoListingView'
import MaintenanceList from './components/MaintenanceList'
import { CountCard } from '../../components/common/CountCard/CountCard'

import clearIcon from '../../assets/images/svgImages/clear_input_icon.svg'
import searchIcon from '../../assets/images/svgImages/Search.svg'
import noListMaintenance from '../../assets/images/svgImages/no-list-maintenance.svg'

const Maintenance = () => {
  const dispatch = useAppDispatch()
  const { maintenances, totalListCount } = useAppSelector(
    (state) => state.maintenanceReducer,
  )

  const [filters, setFilters] = useState<string>('')

  useEffect(() => {
    dispatch(setActivePageHeader('Maintenance'))
    getMaintenanceData()
  }, [])

  useEffect(() => {
    if (filters) {
      const filteredData = maintenances.filter((data) => data.title.toLowerCase().includes(filters.toLowerCase()))
      dispatch(saveMaintenance(filteredData, filteredData.length))
    } else {
      getMaintenanceData()
    }
  }, [filters])

  const getMaintenanceData = async () => {
    try {
      const response = await maintenanceService.getMaintenanceList()
      dispatch(saveMaintenance(response?.results, response?.count))
      dispatch(setActivePageHeader('Maintenance'))
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in get Maintenance List')
        } else {
          ErrorToast('Error in get Maintenance List')
        }
      }
      dispatch(setActivePageHeader('Maintenance'))
    }
  }

  const getTotalCount = () => {
    const TotalCount = maintenances.length
    return TotalCount
  }

  const getTotalCompletedCount = () => {
    const CompletedCount = maintenances.filter(
      (x: MaintenanceListData) => x?.status_name === 'Completed',
    ).length
    return CompletedCount
  }

  const getTotalInProgressCount = () => {
    const pendingCount = maintenances.filter(
      (x: MaintenanceListData) => x?.status_name === 'In-Progress',
    ).length
    return pendingCount
  }

  return (
    <>
      <Row className='mb-4'>
        <Col>
          <h2 className='title'>Maintenance</h2>
        </Col>
      </Row>
      <Row className='mb-4 w-100 d-flex gap-3'>
        <CountCard
          heading='Total Task'
          subHeading={moment().format('MMMM')}
          count={getTotalCount()}
        />
        <CountCard
          heading='Total Completed'
          subHeading={moment().format('MMMM')}
          count={getTotalCompletedCount()}
        />
        <CountCard
          heading='Total In-progress'
          subHeading={moment().format('MMMM')}
          count={getTotalInProgressCount()}
        />
      </Row>
      <Row className='mb-4'>
        <Col md={4}>
          <div className='searchbar'>
            <div className='searchbar-container searchbar-container--bg me-2'>
              <input className='form-control' placeholder='Search by task name' value={filters} onChange={(e) => {
                setFilters(e.target.value)
              }} />
              <div className='search-icon cursor-pointer'>
                <img src={searchIcon} alt='search-icon' />
              </div>
              {filters && (
                <a
                  onClick={() => {
                    setFilters('')
                  }}
                >
                  <img src={clearIcon} className='search-clear cursor-pointer' />
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {maintenances?.length ? (
        <MaintenanceList getMaintenanceData={getMaintenanceData} totalCount={totalListCount} />
      ) : (
        <NoListingView
          image={noListMaintenance}
          noPropText='No tasks'
          addPropText='Tenant has not created any tasks yet.'
        />
      )}
    </>
  )
}

export default Maintenance
