import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHook'
import { Button } from 'reactstrap'
import addBankIcon from '../../../../assets/images/svgImages/add bank_icon.svg'
import PaymentMethod from './payment-method'
import { tenantService } from '../../../../helpers/tenantService'
import { setTenantBankDetails } from '../../../../store/tenant/tenantSlice'
import { useEffect, useState } from 'react'
import {
  setConnectedAccountsDetails,
  setCurrentCardDetail,
} from '../../../../store/setting/settingSlice'
import { settingService } from '../../../../helpers/settingService'
import { AccountDetail } from '../../../../types/setting'
import axios from 'axios'
import { ErrorToast } from '../../../../helpers/toster'
import SelectBankTypeModal from '../../../../components/common/SelectBankTypeModal/SelectBankTypeModal'
import NewCard from './NewCard'

const PaymentMethodLandingPage = () => {
  useEffect(() => {
    checkIfTenant()
    getCreditCardList()
    getConnectedAccounts()
  }, [])

  const { currentCard } = useAppSelector((state) => state.settingReducer)
  const { currentActivatedBankAccount } = useAppSelector((state) => state.tenantReducer)

  const dispatch = useAppDispatch()
  const [isTenant, setIsTenant] = useState(false)
  const [isBankTypeModalVisible, setIsBankTypeModalVisible] = useState(false)
  const [isNewCard, setIsNewCard] = useState(false)

  const checkIfTenant = async () => {
    const response = JSON.parse(localStorage.getItem('pmanagement.user') as string)
    if (response?.portal === 'tenant') {
      setIsTenant(true)
      const tenantConnectedAccount = await tenantService.getPaymentMethod()
      if (tenantConnectedAccount?.data) {
        dispatch(setTenantBankDetails(tenantConnectedAccount?.data))
      }
    }
  }

  const getCreditCardList = async () => {
    const list = await settingService.getCreditCardDetails()
    dispatch(setCurrentCardDetail(list))
  }

  const getConnectedAccounts = async () => {
    try {
      const { accounts } = await settingService.getConnectedBankAccounts()
      dispatch(setConnectedAccountsDetails(accounts as AccountDetail[]))
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in get account details.')
        } else {
          ErrorToast('Error in get account details.')
        }
      }
    }
  }

  const addBank = async (bankType: string) => {
    const response = await settingService.addConnectedAccount(bankType)
    if (response?.success) {
      setIsBankTypeModalVisible(false)
      window.open(response?.redirect_url)
      setIsBankTypeModalVisible(false)
      getConnectedAccounts()
    } else {
      ErrorToast(response?.msg)
    }
  }

  return (
    <>
      {!isTenant && (
        <>
          {!currentCard.card_id && !isNewCard ? (
            <div className='payout-landing-view'>
              <div className='row payout-block-content'>
                <div className='payout-block'>
                  <div className='text-center'>
                    <img className='mt-2' src={addBankIcon} alt='Add bank' />
                    <p className='add-prop mb-2'>{'No Payment Method Attached'}</p>
                    <div className='d-flex align-items-center justify-content-center'>
                      <Button className='btn submit-btn' onClick={() => setIsNewCard(true)}>
                        Add Card
                      </Button>
                      {/* <Button className='btn submit-btn' onClick={() => setIsBankTypeModalVisible(true)}>Add Bank</Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : !isNewCard ? (
            <PaymentMethod />
          ) : (
            <NewCard
              handleBackButton={() => setIsNewCard(false)}
              getCreditCardList={getCreditCardList}
            />
          )}

          {isBankTypeModalVisible && (
            <SelectBankTypeModal
              isModalOpen={isBankTypeModalVisible}
              handleClose={() => setIsBankTypeModalVisible(false)}
              handleBankTypeSelection={(bankType) => addBank(bankType)}
            />
          )}
        </>
      )}

      {isTenant && (
        <>
          {!currentActivatedBankAccount?.id ? (
            <div className='paymentLanding'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='text-center'>
                    <img src={addBankIcon} alt='Add bank' />
                    <p className='paymentLanding-prop'>{'No Payment Method Attached'}</p>
                    <div>
                      <Button className='PaymentAddbutton'>
                        <span></span>Add Bank
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <PaymentMethod />
          )}
        </>
      )}
    </>
  )
}
export default PaymentMethodLandingPage
