import { Button, Col, Row } from 'reactstrap'
import NoListingView from '../../components/common/NoListingView/NoListingView'
import createProp from '../../assets/images/create-prop.png'
import SearchIcon from '../../assets/images/svgImages/Search.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routesConstant'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { useEffect, useState } from 'react'
import { setActivePageHeader } from '../../store/auth/authSlice'
import PropertiesList from './components/PropertiesList'
import { propertiesService } from '../../helpers/propertiesService'
import { savePropertiesListData } from '../../store/properties/propertiesSlice'
import clearIcon from '../../assets/images/svgImages/clear_input_icon.svg'

const Properties = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { propertiesList } = useAppSelector((state) => state.propertiesReducer)
  const [filters, setFilters] = useState<string>("")

  useEffect(() => {
    if (propertiesList.length > 0) {
      dispatch(setActivePageHeader('Properties List'))
    } else {
      dispatch(setActivePageHeader('Properties'))
    }
    getPropertyData()
  }, [])

  useEffect(() => {
    if (filters) {
      const filteredData = propertiesList.filter((data) => data.title.toLowerCase().includes(filters.toLowerCase()))
      dispatch(savePropertiesListData(filteredData, filteredData.length))
    } else {
      getPropertyData()
    }
  }, [filters])

  const getPropertyData = async () => {
    const response = await propertiesService.getPropertyList()
    if (response.results && response.results.length > 0) {
      dispatch(savePropertiesListData(response.results, response.count))
      dispatch(setActivePageHeader('Properties List'))
    } else {
      dispatch(setActivePageHeader('Properties'))
      dispatch(savePropertiesListData([], 0))
    }
  }

  return (
    <>
      <Row className='d-flex align-items-center justify-content-between mb-5'>
        <Col>
          <h2 className='title'>Properties</h2>
        </Col>
        <Col className='d-flex align-items-center justify-content-end '>
          <Button
            className='btn cta ms-4'
            onClick={() => navigate(ROUTES.NEW_PROPERTIES)}
          >
            New Property
          </Button>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col md={4}>
          <div className='searchbar-container me-4'>
            <input className='form-control' placeholder='Search by property name' value={filters} onChange={(e) => { setFilters(e.target.value) }} />
            <div className='search-icon cursor-pointer'>
              <img src={SearchIcon} alt='search-icon' />
            </div>
            {filters && (
              <a
                onClick={() => {
                  setFilters('')
                }}
              >
                <img src={clearIcon} className='search-clear cursor-pointer' />
              </a>
            )}
          </div>
        </Col>
      </Row>
      {propertiesList?.length ? (
        <PropertiesList getPropertyData={getPropertyData} />
      ) : (
        <NoListingView
          noPropText='No properties'
          addPropText="You haven't added any objects yet."
          image={createProp}
          buttonText='Create Property'
          onClick={() => navigate(ROUTES.NEW_PROPERTIES)}
        />
      )}
    </>
  )
}

export default Properties
