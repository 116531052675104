import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import AccountCard, {
  AccountCardProps,
} from '../../../../components/common/AccountCard/AccountCard'
import AddNewCard from './NewCard'
import { settingService } from '../../../../helpers/settingService'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHook'
import {
  setCurrentCardDetail,
  setConnectedAccountsDetails,
} from '../../../../store/setting/settingSlice'
import { setActivePageHeader } from '../../../../store/auth/authSlice'
// import DialogBox from '../../../../components/common/Dialog/Index'
// import addbankDialogImage from '../../../../assets/images/svgImages/housebank_icon.svg'

import { AccountDetail } from '../../../../types/setting'
import { tenantService } from '../../../../helpers/tenantService'
import { setTenantBankDetails } from '../../../../store/tenant/tenantSlice'
import { ErrorToast } from '../../../../helpers/toster'
import axios from 'axios'
import SelectBankTypeModal from '../../../../components/common/SelectBankTypeModal/SelectBankTypeModal'
import slideNextIcon from '../../../../assets/images/svgImages/slide_next_icon.svg'
import slidePrevIcon from '../../../../assets/images/svgImages/slide_prev_icon.svg'
import AddPaymentMethodView from './AddPaymentMethodView'
import addCCImg from '../../../../assets/images/add_cc_img.png'

const PaymentMethod = () => {
  const dispatch = useAppDispatch()
  const [isNewCard, setIsNewCard] = useState(false)
  const [checked] = useState(false)
  const [cardDetails, setCardDetails] = useState({} as AccountCardProps)
  const { currentCard } = useAppSelector((state) => state.settingReducer)
  const { currentActivatedBankAccount } = useAppSelector((state) => state.tenantReducer)

  // const [visible, setVisible] = useState(false)
  // const [redirectUrl, setRedirectUrl] = useState('')
  const [isTenant, setIsTenant] = useState(false)
  const [tenantAccountDetails, setTenantAccountDetail] = useState(
    currentActivatedBankAccount as AccountCardProps,
  )
  const [isLoaded, setIsLoaded] = useState(false)
  const [isBankTypeModalVisible, setIsBankTypeModalVisible] = useState(false)

  // eslint-disable-next-line
  const NextArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <img src={slideNextIcon} alt='slide-next-icon' />
      </div>
    )
  }

  // eslint-disable-next-line
  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <img src={slidePrevIcon} alt='slide-prev-icon' />
      </div>
    )
  }

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />
  // };

  useEffect(() => {
    getCreditCardList()
    getConnectedAccounts()
    dispatch(setActivePageHeader('Settings'))
    checkIfUserTenant()
  }, [])

  const checkIfUserTenant = async () => {
    const response = JSON.parse(localStorage.getItem('pmanagement.user') as string)
    if (response?.portal === 'tenant') {
      setIsTenant(true)
      const tenantConnectedAccount = await tenantService.getPaymentMethod()
      if (tenantConnectedAccount?.data) {
        dispatch(setTenantBankDetails(tenantConnectedAccount?.data))
        setIsLoaded(true)
      }
      const cardData = tenantConnectedAccount?.data
      // let lastValue = tenantConnectedAccount?.data?.id
      // lastValue = lastValue.substr(lastValue.length - 4)
      const TenantAccountCardData = {
        heading: 'Wire Transfer',
        cardId: cardData.id as string,
        created: cardData.created as string,
        expMonth: cardData.email ? cardData.email : 'N/A',
        expYear: cardData.exp_year,
        id: cardData.id,
        isCard: false,
        lastDigiit: cardData.us_bank_account.last4,
      }
      setTenantAccountDetail(TenantAccountCardData)
    }
  }

  const getCreditCardList = async () => {
    const list = await settingService.getCreditCardDetails()
    dispatch(setCurrentCardDetail(list))
    if (list.card_id) {
      const cardInfo: AccountCardProps = {
        heading: 'Card',
        cardId: list.card_id,
        created: list.created,
        expMonth: list.exp_month,
        expYear: list.exp_year,
        id: list.id,
        isCard: true,
        landlord: list.landlord,
        lastDigiit: list.cc_last4,
        holderName: list?.friendly_name,
      }
      setCardDetails(cardInfo)
    } else {
      const cardInfo: AccountCardProps = {
        heading: 'Card',
        cardId: currentCard.card_id,
        created: currentCard.created,
        expMonth: currentCard.exp_month,
        expYear: currentCard.exp_year,
        id: currentCard.id,
        isCard: true,
        landlord: currentCard.landlord,
        lastDigiit: currentCard.cc_last4,
        holderName: currentCard?.friendly_name,
      }
      setCardDetails(cardInfo)
    }
  }

  const isAccountEdit = async (id: string, accountId: string) => {
    const response = await settingService.updateBankAccount(id, accountId)
    if (response.success) {
      // setRedirectUrl(response?.url)
      window.open(response?.url)
      // setVisible(true)
    }
  }

  const getConnectedAccounts = async () => {
    try {
      const { accounts } = await settingService.getConnectedBankAccounts()
      dispatch(setConnectedAccountsDetails(accounts as AccountDetail[]))
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in get account details.')
        } else {
          ErrorToast('Error in get account details.')
        }
      }
    }
  }

  // const processToCardPropsData = (CardPropData: AccountDetail) => {
  //   const accountInfo: AccountCardProps = {
  //     heading: 'Wire Transfer',
  //     cardId: CardPropData?.account_id as string,
  //     created: CardPropData?.created as string,
  //     expMonth: CardPropData?.email,
  //     expYear: currentCard.exp_year,
  //     id: Number(CardPropData?.id),
  //     isCard: false,
  //     lastDigiit: CardPropData?.last4,
  //     holderName: CardPropData?.friendly_name
  //   }
  //   return accountInfo;
  // }

  // const dialogData = () => {
  //   let data = {} as DialogData
  //   data = {
  //     heading: `You're leaving HappyPropy`,
  //     subheading: 'If you trust this link, select it to continue',
  //     link: redirectUrl,
  //     image: addbankDialogImage,
  //     buttonText: 'Go Back',
  //   }
  //   return data
  // }

  const tenantAccountEdit = () => {
    console.log('Tenant Account edit called')
  }

  const addBank = async (bankType: string) => {
    const response = await settingService.addConnectedAccount(bankType)
    if (response?.success) {
      setIsBankTypeModalVisible(false)
      window.open(response?.redirect_url)
      setIsBankTypeModalVisible(false)
      getConnectedAccounts()
    } else {
      ErrorToast(response?.msg)
    }
  }

  return (
    <>
      {!isTenant && (
        <>
          {/* <DialogBox
            open={visible}
            setOpen={(status: boolean) => setVisible(status)}
            isSuccess={true}
            data={dialogData()}
          ></DialogBox> */}

          {isBankTypeModalVisible && (
            <SelectBankTypeModal
              isModalOpen={isBankTypeModalVisible}
              handleClose={() => setIsBankTypeModalVisible(false)}
              handleBankTypeSelection={(bankType) => addBank(bankType)}
            />
          )}

          {!isNewCard ? (
            <Row className='mt-5'>
              <Col md={6}>
                <div className={!checked ? 'borderCheckedCustomClass' : 'borderCustomClass'}>
                  {currentCard?.card_id ? (
                    <AccountCard
                      data={cardDetails}
                      isEdit={() => isAccountEdit('', '')}
                    ></AccountCard>
                  ) : (
                    <AddPaymentMethodView pymtMethodImage={addCCImg} />
                  )}
                </div>

                <div className='pt-5 d-flex justify-content-center'>
                  {currentCard?.card_id ? (
                    <Button className='replaceButton'>
                      <span>Replace Card</span>
                    </Button>
                  ) : (
                    <Button className='replaceButton' onClick={() => setIsNewCard(true)}>
                      <span>Add Card</span>
                    </Button>
                  )}
                </div>
              </Col>

              {/* add bank hide for now */}
              {/* <Col md={6}>
                <div className={checked ? 'borderCheckedCustomClass' : 'borderCustomClass'}>
                  { connectedAccounts.length ? (
                    <Slider {...settings}>
                      { connectedAccounts.map((connectedAccount, index) => (
                        <AccountCard key={index} data={processToCardPropsData(connectedAccount)} isEdit={() => isAccountEdit(connectedAccount.id ? connectedAccount.id : '', connectedAccount.account_id ? connectedAccount.account_id : '')}></AccountCard>
                      ))}
                    </Slider>
                  ) : <AddPaymentMethodView pymtMethodImage={addBankImg} /> }
                </div>
                <div className='pt-5 d-flex justify-content-center'>
                  <Button className='replaceButton' onClick={() => setIsBankTypeModalVisible(true)}>
                    <span>Add Bank Details</span>
                  </Button>
                </div>
              </Col> */}
            </Row>
          ) : (
            <AddNewCard
              getCreditCardList={getCreditCardList}
              handleBackButton={() => setIsNewCard(false)}
            />
          )}
        </>
      )}

      {isTenant && (
        <>
          <Row className='mt-5'>
            {currentActivatedBankAccount?.id && isLoaded && (
              <Col md={6}>
                <div className='d-flex justify-content-center'>
                  <p className='defaultMethodHeading'>
                    <span className='TenantDefaultMethodHeading'>Default Method</span>
                  </p>
                </div>
                <div className='borderCheckedCustomClass'>
                  <AccountCard data={tenantAccountDetails} isEdit={tenantAccountEdit}></AccountCard>
                  <div className='chechbox d-flex justify-content-center mt-4'>
                    <input className='form-check-input' type='checkbox' checked={!checked} />
                  </div>
                </div>

                <div className='pt-5 d-flex justify-content-center'>
                  <Button className='replaceButton'>
                    <span>Replace Bank Details</span>
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  )
}

export default PaymentMethod
