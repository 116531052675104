import { Card, CardBody } from 'reactstrap'

import NotificationGradientIcon from '../../../assets/images/svgImages/Notification-gradient.svg'

export interface CardProps {
  heading?: string
  subHeading?: string
  count?: number
  icon?: string
}

export const CountCard = (props: CardProps) => {
  const { heading = '', subHeading = '', count = 0 } = props;

  return (
    <Card className='count-card'>
      <CardBody>
        <div className="icon">
          {props.icon ? <img src={props.icon} alt="icon" /> : <img src={NotificationGradientIcon} alt="icon" />}
        </div>
        <div className="content">
          <div className="heading">
            <span className="title">{heading}</span>
            <span className="month">{subHeading}</span>
          </div>
          <span className="count">{count}</span>
        </div>
      </CardBody>
    </Card>
  )
}