import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import {
  RepeatTypeOptions,
  WeekDaysValue,
  Hours,
  Minutes,
  Meridiem,
  DaysSelection,
} from '../../../constants/appConstants'
import { ChangeEvent, useState } from 'react'
import closeIcon from '../../../assets/images/svgImages/Close.svg'
import { SchedulePayload } from '../../../types/todoTasks'
import moment from 'moment-timezone';

interface CustomRecurrenceModalProps {
  isModalOpen: boolean
  handleClose: (customRecurrenceData?: SchedulePayload) => void
  customRecurrenceData: SchedulePayload
}

const CustomRecurrenceModal = (props: CustomRecurrenceModalProps) => {
  const { isModalOpen, handleClose, customRecurrenceData } = props
  const [recurrenceRepeatType, setRecurrenceRepeatType] = useState<string>(
    customRecurrenceData.repeat_type,
  )
  const [recurrenceRepeatFrequency, setRecurrenceRepeatFrequency] = useState<string>(
    customRecurrenceData.repeat_frequency,
  )
  const [monthlyRecurrenceTypeValue, setMonthlyRecurrenceTypeValue] =
    useState<number>()
  const [weekDays, setWeekdays] = useState<string[]>([])

  const [hour, setHour] = useState(
    customRecurrenceData.at_hour ? customRecurrenceData.at_hour : parseInt(moment().format('h')),
  )
  const [minute, setMinute] = useState(
    customRecurrenceData.at_minutes
      ? customRecurrenceData.at_minutes
      : parseInt(moment().format('mm')),
  )
  const [meridiem, setMeridiem] = useState(
    customRecurrenceData.meridiem
      ? customRecurrenceData.meridiem
      : moment().format('a') === 'am'
        ? 'a'
        : 'p',
  )

  const handleWeekdaysCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    if (weekDays.includes(e.target.value)) {
      let weekDaysValues = weekDays.slice()
      weekDaysValues = weekDaysValues.filter((data) => data !== e.target.value)
      setWeekdays(weekDaysValues)
    } else {
      const weekDaysValues = weekDays.slice()
      weekDaysValues.push(e.target.value)
      setWeekdays(weekDaysValues)
    }
  }

  const handleSaveModal = () => {
    const customRecurrencesValues: SchedulePayload = {
      repeat_frequency: recurrenceRepeatFrequency,
      repeat_type: recurrenceRepeatType,
      at_hour: hour,
      at_minutes: minute,
      meridiem: meridiem,
      timezone: moment.tz.guess(),
      ends: customRecurrenceData.ends,
      ends_on_date: customRecurrenceData.ends_on_date,
    }
    if (recurrenceRepeatType === 'w') {
      customRecurrencesValues.week_days = weekDays
    }
    if (recurrenceRepeatType === 'm') {
      customRecurrencesValues.repeat_on_day = monthlyRecurrenceTypeValue ? `${monthlyRecurrenceTypeValue}` : `${moment().date()}`
    }

    handleClose(customRecurrencesValues)
  }

  const repeatOptionView = () => {
    if (recurrenceRepeatType === 'w') {
      return (
        <Col xs={12}>
          <Label>Repeat On</Label>
          <div className='d-flex align-items-center flex-wrap mb-3'>
            <FormGroup className='me-3 mb-3' check>
              <Label check>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='repeat-on'
                  value={WeekDaysValue.SU}
                  onChange={handleWeekdaysCheckbox}
                />{' '}
                Su
              </Label>
            </FormGroup>
            <FormGroup className='me-3 mb-3' check>
              <Label check>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='repeat-on'
                  value={WeekDaysValue.MO}
                  onChange={handleWeekdaysCheckbox}
                />{' '}
                Mo
              </Label>
            </FormGroup>
            <FormGroup className='me-3 mb-3' check>
              <Label check>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='repeat-on'
                  value={WeekDaysValue.TU}
                  onChange={handleWeekdaysCheckbox}
                />{' '}
                Tu
              </Label>
            </FormGroup>
            <FormGroup className='me-3 mb-3' check>
              <Label check>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='repeat-on'
                  value={WeekDaysValue.WE}
                  onChange={handleWeekdaysCheckbox}
                />{' '}
                We
              </Label>
            </FormGroup>
            <FormGroup className='me-3 mb-3' check>
              <Label check>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='repeat-on'
                  value={WeekDaysValue.TH}
                  onChange={handleWeekdaysCheckbox}
                />{' '}
                Th
              </Label>
            </FormGroup>
            <FormGroup className='me-3 mb-3' check>
              <Label check>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='repeat-on'
                  value={WeekDaysValue.FR}
                  onChange={handleWeekdaysCheckbox}
                />{' '}
                Fr
              </Label>
            </FormGroup>
            <FormGroup className='me-3 mb-3' check>
              <Label check>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='repeat-on'
                  value={WeekDaysValue.SA}
                  onChange={handleWeekdaysCheckbox}
                />{' '}
                Sa
              </Label>
            </FormGroup>
          </div>
        </Col>
      )
    } else if (recurrenceRepeatType === 'm') {
      return (
        <Col xs={12}>
          <Label>Repeat On</Label>
          <FormGroup className='monthly-recurrence-container'>
            {DaysSelection.map((day, index) => (
              <Label check key={index} className='monthly-recurrence-input'>
                <Input
                  type='radio'
                  name='monthlyRecurrenceType'
                  value={day}
                  checked={monthlyRecurrenceTypeValue === day}
                  onChange={(e) => {
                    setMonthlyRecurrenceTypeValue(parseInt(e.target.value))
                  }}
                />
                {day}
              </Label>
            ))}
          </FormGroup>
        </Col>
      )
    } else {
      return ''
    }
  }

  return (
    <Modal
      size='md'
      centered
      className='common-alert-modal custom-recurrence-modal'
      isOpen={isModalOpen}
      toggle={() => handleClose()}
    >
      <div className='modal-header-container'>
        <div className='close-icon text-end cursor-pointer' onClick={() => handleClose()}>
          <img src={closeIcon} />
        </div>
      </div>
      <ModalBody className='common-body-container px-5 py-3'>
        <div className='modal-body-content h-100 w-100'>
          <Form>
            <Row className='mb-3'>
              <div className='header-heading pb-1'>Custom recurrence</div>
              <span>Set up your custom notification schedule.</span>
            </Row>
            <Row>
              <Label>Repeat Every<span className="required">*</span></Label>
              <Col md={4}>
                <FormGroup>
                  <Input
                    id='repeat'
                    name='repeat'
                    type='number'
                    placeholder='Repeat'
                    min={1}
                    max={7}
                    bsSize='lg'
                    value={recurrenceRepeatFrequency}
                    onChange={(e) => setRecurrenceRepeatFrequency(e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={8}>
                <FormGroup>
                  <Input
                    id='repeat_type'
                    name='repeat_type'
                    type='select'
                    bsSize='lg'
                    onChange={(e) => setRecurrenceRepeatType(e.target.value)}
                    value={recurrenceRepeatType}
                  >
                    {RepeatTypeOptions.map((repeatTypeOption, index) => (
                      <option key={index} value={repeatTypeOption.value}>
                        {repeatTypeOption.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              {repeatOptionView()}

              <FormGroup>
                <Row>
                  <Label>At<span className="required">*</span></Label>
                </Row>
                <div className='d-flex flex-row ModalHourInputs'>
                  <Col>
                    <Input
                      id='at_hour'
                      type='select'
                      name='at_hour'
                      value={hour}
                      onChange={(e) => {
                        setHour(parseInt(e.target.value))
                      }}
                    >
                      <>
                        {Hours.map((data, index) => (
                          <option key={index} value={data}>
                            {data}
                          </option>
                        ))}
                      </>
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      id='at_minutes'
                      type='select'
                      name='at_minutes'
                      value={minute}
                      onChange={(e) => {
                        setMinute(parseInt(e.target.value))
                      }}
                    >
                      <>
                        {Minutes.map((data, index) => (
                          <option key={index} value={data}>
                            {data}
                          </option>
                        ))}
                      </>
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      id='meridiem'
                      type='select'
                      name='meridiem'
                      value={meridiem}
                      onChange={(e) => {
                        setMeridiem(e.target.value)
                      }}
                    >
                      <>
                        {Meridiem.map((meridiem, index) => (
                          <option key={index} value={meridiem.value}>
                            {meridiem.label}
                          </option>
                        ))}
                      </>
                    </Input>
                  </Col>
                </div>
              </FormGroup>
            </Row>

            <div className='modal-button d-flex justify-content-end mt-3'>
              <Button
                className='btn cta me-2'
                disabled={
                  (recurrenceRepeatType === 'w' && weekDays.length === 0)
                }
                onClick={() => handleSaveModal()}
              >
                Save
              </Button>
              <Button
                className='btn simple'
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CustomRecurrenceModal
