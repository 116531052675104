import createProp from '../../../assets/images/create-prop.png'

export interface NoListingProps {
    noPropText?: string,
    addPropText?: string,
    image?: string,
    buttonText?: string,
    onClick?: () => void
}

const NoListingView = (props: NoListingProps) => {
    const { addPropText, noPropText } = props

    return (
        <div className="no-list-view">
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <img src={props.image} />
                        <p className='no-prop'>{noPropText}</p>
                        <p className='add-prop'>{addPropText}</p>
                        {props.buttonText && (
                            <button className='btn cta' onClick={props.onClick}>{props.buttonText}</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoListingView;