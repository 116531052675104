import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import verifyEmailImg from "../../assets/images/verify_email.png";
import { API_URLS } from "../../constants/appConstants";
import { ROUTES } from "../../constants/routesConstant";
import API from "../../helpers/api";
import { ErrorToast, SuccessToast } from "../../helpers/toster";

import PrivacyTerms from "../../components/common/PrivacyTerms/component";

const EmailVerification = () => {
    const navigate = useNavigate();
    const location = useLocation().search;
    const queryParams = new URLSearchParams(location);
    const token = queryParams.get('token');
    const uidb64 = queryParams.get('uidb64');
    const email = queryParams.get('email');

    const handleVerifyEmail = async () => {
        const verifyData = {
            token,
            uidb64
        }
        try {
            const response = await API.post(API_URLS.EMAIL_VERIFICATION, verifyData);
            if (response.status === 201 || response.status === 200) {
                navigate(ROUTES.LOGIN);
                SuccessToast("Email verified successfully. Please login");
            } else {
                ErrorToast("Error in email verification");
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.data?.detail) {
                    ErrorToast(error.response?.data?.detail);
                    return false;
                }
            }
            ErrorToast("Token expired");
        }
    }

    return (
        <div className="container verification-wrapper position-relative min-vh-100">
            <div className="verify-account-heading">Verify Account</div>
            <div className="pb-5">
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <div className="verify-email-img mb-4"><img alt="verify-email" src={verifyEmailImg} /></div>
                    <div className="verify-email-text">Verify Your Email Address</div>
                    <div className="continue-text">To continue using HappyPropy, Please verify your email address:</div>
                    <div className="email-text">{email}</div>
                    <div className="trouble-verifying">Trouble Verifying?</div>
                    <Button className="btn cta mt-3" onClick={handleVerifyEmail}>Login</Button>
                </div>
            </div>
            <PrivacyTerms />
        </div>
    )
}

export default EmailVerification;