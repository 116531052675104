import { useRef } from "react";
import addImageIcon from "../../../assets/images/upload-images.png";
import addNewImagesIcon from "../../../assets/images/add_images.png";
import deleteImageIcon from "../../../assets/images/svgImages/delete_img_icon.svg";
import { Button } from "reactstrap";
import { Images } from "../../../types/properties";

interface UploadImagesProps {
    handleFileChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    files: File[];
    handleFileDelete: (index: number) => void;
    isEditFormMode?: boolean;
    imagesData?: Images[];
    handleSavedFileDelete?: (id: number) => void;
}

const UploadImages = (props: UploadImagesProps) => {
    const { files, handleFileChange, handleFileDelete, imagesData = [], handleSavedFileDelete } = props
    const inputFile = useRef<HTMLInputElement>(null);

    return (
        <div className="upload-image-inner flex-column d-flex align-items-center justify-content-center">
            {files?.length > 0 || imagesData?.length > 0 ? (
                <div className="d-flex align-items-center flex-wrap">
                    {files?.length ? files.map((data, index) =>
                        <div key={index} className="upload-image-view me-4 mt-2" onClick={() => handleFileDelete(index)}>
                            <div className="overlay">
                                <div className="delete-img-icon">
                                    <img alt="delete-icon" src={deleteImageIcon} />
                                </div>
                                <div className="remove-txt mt-1">Remove</div>
                            </div>
                            <img src={URL.createObjectURL(data)} />
                        </div>
                    ) : ''}
                    {imagesData?.length ? imagesData.map((data, index) =>
                        <div key={index} className="upload-image-view me-4 mt-2" onClick={() => handleSavedFileDelete && handleSavedFileDelete(data.id)}>
                            <div className="overlay">
                                <div className="delete-img-icon">
                                    <img alt="delete-icon" src={deleteImageIcon} />
                                </div>
                                <div className="remove-txt mt-1">Remove</div>
                            </div>
                            <img src={data.image} />
                        </div>
                    ) : ''}
                    <div className="upload-image-icon-view d-flex align-items-center justify-content-center flex-column cursor-pointer" onClick={() => inputFile.current && inputFile.current.click()}>
                        <img src={addImageIcon} />
                        <div className="upload-icon-text">Upload</div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="upload-image mb-2">
                        <img alt="upload-pic" src={addNewImagesIcon} />
                    </div>
                    <Button className="btn flat" onClick={() => inputFile.current && inputFile.current.click()}>Click to upload or drag and drop</Button>
                    <span className="image-max-text">Maximum file size 5 MB</span>
                </>
            )}
            <input type="file" id="property-file" accept="image/*" onChange={handleFileChange} ref={inputFile} style={{ display: 'none' }} multiple />
        </div>
    )
}

export default UploadImages