import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment-timezone';
import axios from 'axios'
import * as Yup from 'yup'

import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { Formik, Form } from 'formik'
import { Calendar } from 'primereact/calendar'
import {
  TreeSelect,
  TreeSelectChangeEvent,
  TreeSelectSelectionKeysType,
} from 'primereact/treeselect'
import { TreeNode } from 'primereact/treenode';
import {
  CreateTodoTasksFormStates,
  SchedulePayload,
  TreeSelectUnits,
  TaskDetailById,
} from '../../../types/todoTasks'
import {
  NotificationRecipientOptions,
  NotificationsTypetOptions,
  TodoScheduleOptions,
  Hours,
  Minutes,
  Meridiem,
} from '../../../constants/appConstants'
import CustomRecurrenceModal from './CustomRecurrenceModal'
import { ROUTES } from '../../../constants/routesConstant'
import { todoService } from '../../../helpers/todoService'
import { useAppDispatch } from '../../../hooks/storeHook'
import { setActivePageHeader } from '../../../store/auth/authSlice'
import { ErrorToast } from '../../../helpers/toster'
import { getCurrentDayOfWeek } from '../../../helpers/utils'
import { OverlayPanel } from 'primereact/overlaypanel'


const initialValues: CreateTodoTasksFormStates = {
  name: '',
  schedule: {
    repeat_frequency: '1',
    repeat_type: '',
    ends: 'n',
    ends_on_date: null,
    at_hour: parseInt(moment().format('h')),
    at_minutes: parseInt(moment().format('m')),
    meridiem: moment().format('a') === 'am' ? 'a' : 'p',
    timezone: '',
  },
  units: [],
  description: '',
  notification_recipient: '',
  notification_means: '',
  last_run: '',
}

type SelectedUnitsState = Record<string, TreeSelectSelectionKeysType>

const createTodoTasksValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Task name is required*'),
    schedule: Yup.object().shape({
      repeat_frequency: Yup.string().required('Repeat Frequency is required*'),
      repeat_type: Yup.string().required('Repeat Type is required*'),
      ends: Yup.string().required('Repeat end is required*'),
    }),
    notification_recipient: Yup.string().required('Notification recipient is required*'),
    description: Yup.string().optional(),
    units: Yup.array().required('Property & units are required*'),
    notification_means: Yup.string().required('Notification type is required*'),
  })

const CreateTodoTasks = () => {
  const ModalHourContainerRef = useRef<OverlayPanel>(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { taskId } = useParams()
  const [isEditMode] = useState(taskId ? true : false)
  const [isRecurrenceModalOpen, setIsRecurrenceModalOpen] = useState(false)
  const [formInitialValues, setFormInitialValues] = useState(initialValues)
  const [treeSelectUnitsGroups, setTreeSelectUnitsGroups] = useState<TreeNode[] | undefined>([])
  const [selectedNodeKeys, setSelectedNodeKeys] = useState<
    string | TreeSelectSelectionKeysType | TreeSelectSelectionKeysType[] | null | undefined
  >({})
  const [customRecurrenceData, setCustomRecurrenceData] = useState<SchedulePayload>({
    repeat_frequency: '1',
    repeat_type: 'd',
    ends: 'n',
    ends_on_date: null,
    at_hour: parseInt(moment().format('h')),
    at_minutes: parseInt(moment().format('m')),
    meridiem: moment().format('a') === 'am' ? 'a' : 'p',
    timezone: moment.tz.guess(),
  })
  const [DisplayHourModal, setdisplayHourModal] = useState(false)
  const [endsOnDate, setEndsOnDate] = useState<Date | null>()

  useEffect(() => {
    dispatch(setActivePageHeader(isEditMode ? 'Edit Task' : 'New Task'))

    if (isEditMode) {
      getTaskData()
    }
  }, [])

  useEffect(() => {
    getDisplayPropertiesData()
  }, [selectedNodeKeys])

  const getDisplayPropertiesData = async () => {
    const response = await todoService.getTreeSelectUnitsGroups()
    if (response && response.length > 0) {
      processTreeSelectData(response)
    }
  }

  let selectedTaskDetail: TaskDetailById
  const getTaskData = async () => {
    const response = await todoService.getTaskById(taskId)
    if (axios.isAxiosError(response)) {
      if (response.response?.data?.detail) {
        ErrorToast(response.response?.data?.detail || 'Error in get tenant data')
      } else {
        ErrorToast('Error in get tenant data')
      }
    } else {
      selectedTaskDetail = response

      setFormInitialValues({
        name: response.name,
        schedule: {
          repeat_frequency: response?.schedule.repeat_frequency,
          repeat_type: response?.schedule.repeat_type === 'w' && response?.schedule.repeat_frequency === 2 ? '2w' : response?.schedule.repeat_type,
          ends: response?.schedule.ends,
          ends_on_date: response?.schedule.ends_on_date
            ? new Date(response?.schedule.ends_on_date)
            : null,
          at_hour: response?.schedule.at_hour,
          at_minutes: response?.schedule.at_minutes,
          meridiem: response?.schedule.meridiem,
          timezone: response?.schedule.timezone,
        },
        units: response.units ? response.units.map((unit: number) => unit) : [],
        description: response.description,
        notification_recipient: response.notification_recipient,
        notification_means: response.notification_means,
        last_run: response.last_run,
      })
      setCustomRecurrenceData(response.schedule)
    }
    selectedUnitsData(response.units)
    setEndsOnDate(
      response.schedule.ends_on_date ? new Date(response.schedule.ends_on_date) : null,
    )
  }

  const selectedUnitsData = (units: number[]) => {
    const newSelectedUnits: TreeSelectSelectionKeysType = {}
    units.forEach((unit: number) => {
      newSelectedUnits[unit] = {
        checked: true,
        partialChecked: false,
      }
    })
    setSelectedNodeKeys(newSelectedUnits)
  }

  const processTreeSelectData = (treeSelectData: TreeSelectUnits[]) => {
    const treeSelectDataArr: TreeNode[] | undefined = treeSelectData
      .map((data: TreeSelectUnits, index) => {
        return {
          key: `property${index}-${data.id}`,
          label: data.title,
          children: data.units.map((unit) => {
            return {
              key: unit.id,
              label: unit.unit_name,
            }
          }),
        }
      })
      .filter((data) => data.children.length > 0)
    setTreeSelectUnitsGroups(treeSelectDataArr)
  }

  const handleSubmit = async (formvalues: CreateTodoTasksFormStates) => {

    const lastRunDate = new Date()
    formvalues.last_run = lastRunDate.toISOString()

    let scheduleData: SchedulePayload = {
      repeat_frequency: '1',
      repeat_type: formvalues.schedule.repeat_type,
      ends: endsOnDate ? 'o' : 'n',
      at_hour: formvalues.schedule.at_hour,
      at_minutes: formvalues.schedule.at_minutes,
      meridiem: formvalues.schedule.meridiem,
      ends_on_date: moment(endsOnDate).format('yyyy-MM-DD').toString() || null,
      timezone: moment.tz.guess(),
    }

    if (formvalues.schedule.repeat_type === 'w' || formvalues.schedule.repeat_type === '2w') {
      scheduleData = {
        ...scheduleData,
        repeat_frequency: formvalues.schedule.repeat_type === '2w' ? '2' : '1',
        repeat_type:
          formvalues.schedule.repeat_type === '2w' ? 'w' : formvalues.schedule.repeat_type,
        week_days: [getCurrentDayOfWeek()],
      }
    } else if (formvalues.schedule.repeat_type === 'm') {
      scheduleData = {
        ...scheduleData,
        repeat_frequency: '1',
        repeat_type: formvalues.schedule.repeat_type,
        repeat_on_day: moment().date().toString(),
      }
    }
    formvalues.schedule = scheduleData
    if (formvalues.schedule.repeat_type === 'custom') {
      formvalues.schedule = customRecurrenceData
    }
    if (isEditMode) {
      const id = taskId ? parseInt(taskId) : null
      try {
        await todoService.UpdateTask(id, formvalues)
        navigate(ROUTES.TODO)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.data) {
            if (err.response?.data?.email) {
              ErrorToast(err.response?.data?.email[0] || 'Error trying to uptade task')
            } else if (err.response?.data?.unit) {
              ErrorToast(err.response?.data?.unit[0] || 'Error trying to uptade task')
            } else {
              ErrorToast('Error trying to uptade task')
            }
          } else {
            ErrorToast('Error trying to uptade task')
          }
        } else {
          ErrorToast('Error trying to uptade task')
        }
      }
    } else {
      try {
        await todoService.CreateToDoTasks(formvalues)
        navigate(ROUTES.TODO)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.data) {
            if (err.response?.data?.email) {
              ErrorToast(err.response?.data?.email[0] || 'Error creting task')
            } else if (err.response?.data?.unit) {
              ErrorToast(err.response?.data?.unit[0] || 'Error creting task')
            } else {
              ErrorToast('Error creting task')
            }
          } else {
            ErrorToast('Error creting task')
          }
        } else {
          ErrorToast('Error creting task')
        }
      }
    }
  }

  const frecuencyTextChanged = (frecuency_repeat: string) => {
    let frecuencyText
    switch (frecuency_repeat) {
      case 'd':
        frecuencyText = 'every day'
        break
      case 'w':
        frecuencyText = 'every ' + moment().format('dddd')
        break
      case '2w':
        frecuencyText = 'every second and forth ' + moment().format('dddd')
        break
      case 'm':
        frecuencyText = 'on ' + moment().format('Do') + ' of each month'
        break
      case 'y':
        frecuencyText = 'on ' + moment().format('MMMM Do') + ' of each year'
        break
      default:
        frecuencyText = ''
        break
    }

    return frecuencyText
  }

  return (
    <>
      <div className='new-property col-10'>
        <h2 className="title mb-5">{isEditMode ? 'Edit Task' : 'Create Task'}</h2>
        <Formik
          initialValues={formInitialValues}
          enableReinitialize={true}
          onSubmit={(values: CreateTodoTasksFormStates) => handleSubmit(values)}
          validationSchema={() => createTodoTasksValidationSchema()}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            dirty,
            isValid,
          }) => (
            <Form>
              <fieldset>
                <legend>Main information</legend>
                <Row>
                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label for='email'>
                        Name<span className='required-astrick'>*</span>
                      </Label>
                      <Input
                        id='name'
                        name='name'
                        placeholder='Enter a Task Name'
                        onChange={handleChange}
                        value={values.name}
                        onBlur={handleBlur}
                        maxLength={40}
                      />
                      {touched.name && errors.name ? (
                        <span className='error-text text-danger'>{errors.name}</span>
                      ) :
                        <span className="input-group-caption">Maximum of 40 characters</span>}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Recipient<span className='required-astrick'>*</span>
                      </Label>
                      <Input
                        id='notification_recipient'
                        name='notification_recipient'
                        type='select'
                        bsSize='lg'
                        onBlur={handleBlur}
                        value={values.notification_recipient}
                        onChange={(e) => setFieldValue('notification_recipient', e.target.value)}
                      >
                        <option disabled value={''}>
                          {' '}
                          Select a Notification Recipient
                        </option>
                        {NotificationRecipientOptions.map(
                          (notificationRecipientOption, index) => (
                            <option key={index} value={notificationRecipientOption.value}>
                              {notificationRecipientOption.label}
                            </option>
                          ),
                        )}
                      </Input>
                      {touched.notification_recipient && errors.notification_recipient && (
                        <span className='error-text text-danger'>
                          {errors.notification_recipient}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {values.notification_recipient !== 'm' && (
                      <FormGroup>
                        <Label>
                          Properties & Units<span className='required-astrick'>*</span>
                        </Label>
                        <div className='w-100 flex justify-content-center tree-select'>
                          <TreeSelect
                            id='units'
                            name='units'
                            value={selectedNodeKeys}
                            onChange={(e: TreeSelectChangeEvent) => {
                              if (e.value) {
                                setSelectedNodeKeys(e.value)
                                const treeSelection = e.value
                                const unitsArr = []
                                for (const unit in treeSelection as TreeSelectSelectionKeysType) {
                                  if (!unit.includes('property')) {
                                    unitsArr.push(unit)
                                  }
                                }
                                setFieldValue('units', unitsArr)
                                setSelectedNodeKeys(e.value)
                              } else {
                                setSelectedNodeKeys(e.value)
                              }
                            }}
                            options={treeSelectUnitsGroups}
                            metaKeySelection={false}
                            selectionMode='checkbox'
                            display='chip'
                            placeholder='Select a property'
                          />
                          {touched.units && errors.units && (
                            <span className='error-text text-danger'>{errors.units}</span>
                          )}
                        </div>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <FormGroup>
                  <Label>Description</Label>
                  <Input
                    id='description'
                    name='description'
                    type='textarea'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    maxLength={400}
                  />
                  <span className="input-group-caption">Maximum of 400 characters</span>
                </FormGroup>
              </fieldset>
              <fieldset>
                <legend>Details</legend>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Notification Type <span className='required-astrick'>*</span>
                      </Label>
                      <Input
                        id='notification_means'
                        name='notification_means'
                        type='select'
                        bsSize='lg'
                        onBlur={handleBlur}
                        value={values.notification_means}
                        onChange={(e) => setFieldValue('notification_means', e.target.value)}
                      >
                        <option disabled value={''}>
                          Select a notification type
                        </option>
                        {NotificationsTypetOptions.map((notificationRecipientOption, index) => (
                          <option key={index} value={notificationRecipientOption.value}>
                            {notificationRecipientOption.label}
                          </option>
                        ))}
                      </Input>
                      {touched.notification_means && errors.notification_means ?
                        <span className='error-text text-danger'>
                          {errors.notification_means}
                        </span>
                        : <span className='form-info-text'>
                          Note that if you purchased a notification type different from the one
                          selected for this task, notifications for this task will not be sent.
                        </span>}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Schedule<span className='required-astrick'>*</span>
                      </Label>
                      <Input
                        id='schedule'
                        name='schedule'
                        type='select'
                        bsSize='lg'
                        onBlur={handleBlur}
                        value={values.schedule.repeat_type}
                        onChange={(e) => {
                          if (e.target.value === 'custom') {
                            const scheduleData = {
                              repeat_frequency: '1',
                              repeat_type: e.target.value,
                              ends: values.schedule.ends,
                              at_hour: values.schedule.at_hour,
                              at_minutes: values.schedule.at_minutes,
                              meridiem: values.schedule.meridiem,
                              timezone: moment.tz.guess(),
                            }
                            setFieldValue('schedule', scheduleData)
                            setIsRecurrenceModalOpen(true)
                          } else {
                            const scheduleData = {
                              repeat_frequency: '1',
                              repeat_type: e.target.value,
                              ends: values.schedule.ends,
                              at_hour: values.schedule.at_hour,
                              at_minutes: values.schedule.at_minutes,
                              meridiem: values.schedule.meridiem,
                              timezone: moment.tz.guess(),
                            }
                            setFieldValue('schedule', scheduleData)
                          }
                        }}
                      >
                        <option disabled value={''}>
                          {' '}
                          Select a Date
                        </option>
                        {TodoScheduleOptions.map((todoScheduleOption, index) => (
                          <option key={index} value={todoScheduleOption.value}>
                            {todoScheduleOption.label}
                          </option>
                        ))}
                      </Input>
                      {touched.schedule && errors.schedule && (
                        <>
                          <span className='error-text text-danger'>
                            {errors.schedule.repeat_frequency}
                          </span>
                          <span className='error-text text-danger'>
                            {errors.schedule.repeat_type}
                          </span>
                          <span className='error-text text-danger'>
                            {errors.schedule.repeat_on}
                          </span>
                        </>
                      )}
                      {values.schedule.repeat_type &&
                        values.schedule.repeat_type !== 'custom' && (
                          <>
                            <span className='form-info-text'>
                              Will be sent {frecuencyTextChanged(values.schedule.repeat_type)} at{' '}
                              <a
                                className='frecuencyScheduleHour'
                                onClick={(e) => {
                                  ModalHourContainerRef.current?.toggle(e)
                                }}
                                onBlur={() => { setdisplayHourModal(false) }}
                              >
                                {values.schedule.at_hour}:{values.schedule.at_minutes < 10 ? `0${values.schedule.at_minutes}` : values.schedule.at_minutes}{' '}
                                {values.schedule.meridiem === 'a' ? 'AM' : 'PM'}
                              </a>
                            </span>

                            <OverlayPanel className='ModalHour' ref={ModalHourContainerRef}>
                              <Row>
                                <span>Will be sent on</span>
                              </Row>
                              <div className='d-flex flex-row ModalHourInputs'>
                                <Col>
                                  <Input
                                    id='at_hour'
                                    type='select'
                                    name='at_hour'
                                    value={values.schedule.at_hour}
                                    onChange={(e) => {
                                      setFieldValue('schedule.at_hour', parseInt(e.target.value))
                                    }}
                                  >
                                    <>
                                      {Hours.map((data, index) => (
                                        <option key={index} value={data}>
                                          {data}
                                        </option>
                                      ))}
                                    </>
                                  </Input>
                                </Col>
                                <Col>
                                  <Input
                                    id='at_minutes'
                                    type='select'
                                    name='at_minutes'
                                    value={values.schedule.at_minutes}
                                    onChange={(e) => {
                                      setFieldValue('schedule.at_minutes', parseInt(e.target.value))
                                    }}
                                  >
                                    <>
                                      {Minutes.map((data, index) => (
                                        <option key={index} value={data}>
                                          {data}
                                        </option>
                                      ))}
                                    </>
                                  </Input>
                                </Col>
                                <Col>
                                  <Input
                                    id='meridiem'
                                    type='select'
                                    name='meridiem'
                                    value={values.schedule.meridiem}
                                    onChange={(e) => {
                                      setFieldValue('schedule.meridiem', e.target.value)
                                    }}
                                  >
                                    <>
                                      {Meridiem.map((meridiem, index) => (
                                        <option key={index} value={meridiem.value}>
                                          {meridiem.label}
                                        </option>
                                      ))}
                                    </>
                                  </Input>
                                </Col>
                              </div>
                            </OverlayPanel>
                          </>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Notifications End Date</Label>
                      <Calendar
                        showIcon
                        onChange={(e) => {
                          const scheduleData = {
                            repeat_frequency: values.schedule.repeat_frequency,
                            repeat_type: values.schedule.repeat_type,
                            ends: e ? 'o' : 'n',
                            at_hour: values.schedule.at_hour,
                            at_minutes: values.schedule.at_minutes,
                            meridiem: values.schedule.meridiem,
                            ends_on_date: moment(e.value).format('yyyy-MM-DD').toString(),
                            timezone: moment.tz.guess(),
                          }
                          setFieldValue('schedule', scheduleData)
                        }}
                        value={endsOnDate}
                        minDate={new Date()}
                        dateFormat='yy-mm-dd'
                        placeholder='Select a date'
                        name='notification_end_date'
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </fieldset>

              <div className='submit-wrapper'>
                <Button className='btn cta me-2' disabled={!dirty || !isValid}>
                  {isEditMode ? 'Save task' : 'Create task'}
                </Button>
                <Button
                  className='btn simple'
                  onClick={() => navigate(ROUTES.TODO)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <CustomRecurrenceModal
        isModalOpen={isRecurrenceModalOpen}
        customRecurrenceData={customRecurrenceData}
        handleClose={(customRecurrenceData) => {
          if (customRecurrenceData) {
            setCustomRecurrenceData(customRecurrenceData)
          }
          setIsRecurrenceModalOpen(false)
        }}
      />
    </>
  )
}

export default CreateTodoTasks
