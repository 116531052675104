import { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import moment from "moment";

import { Formik, Form } from 'formik';
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Tooltip } from 'primereact/tooltip';
import { Calendar } from "primereact/calendar";
import { API_URLS } from "../../../constants/appConstants";
import { ROUTES } from "../../../constants/routesConstant";
import { savePropertiesListData } from "../../../store/properties/propertiesSlice";
import { setActivePageHeader } from "../../../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHook";
import { propertiesService } from "../../../helpers/propertiesService";
import { ErrorToast, SuccessToast } from "../../../helpers/toster";
import { AddUnitFormStates, Images, AddNewAdvantageProperty } from "../../../types/properties";
import UploadImages from "../../../components/common/UploadImages/UploadImages";
import CommonAlertModal from "../../../components/common/CommonAlertModal/CommonAlertModal";
import NewAdvantageModal from "../../../components/common/NewAdvantageModal/NewAdvantageModal";

import trashIcon from "../../../assets/images/svgImages/trash_icon.svg";
import clearIcon from '../../../assets/images/svgImages/clear_input_icon.svg'
import cartFilledIcon from '../../../assets/images/svgImages/cart_filled_icon.svg'
import infoIcon from "../../../assets/images/svgImages/tooltip.svg"

const addUnitsValidationSchema = () =>
    Yup.object().shape({
        unit_name: Yup.string().required('Unit name is required*'),
        area_size: Yup.string().required('Area Size is required*'),
        monthly_rent: Yup.number().required('Monthly rent is required*').min(1, "Monthly rent should be greater than 0"),
        rent_due_day: Yup.number().required('Rent due day is required*').min(1, "Rent due day must be minimum 1*").max(31, "Rent due day must be less than or equal to 31"),
        property: Yup.number().required('Property is required*').min(1, "Property is required*"),
        features: Yup.array()
            .min(1, "Please add min one features.")
            .nullable(),
    })

const AddUnits = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { unitId } = useParams();
    const { selectedProperty, propertiesList } = useAppSelector((state) => state.propertiesReducer);
    const [showOutOfUnitConnectsModal, setShowOutOfUnitConnectsModal] = useState(false)
    const [showAddAdvantageModal, setShowAddAdvantageModal] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [tenantsData, setTenantsData] = useState<any>([])
    const initialValues: AddUnitFormStates = {
        unit_name: '',
        area_size: '',
        monthly_rent: '',
        rent_due_day: '',
        features: [],
        description: '',
        property: selectedProperty?.id || 0,
        tenant: null,
        lease_start_date: null,
        lease_end_date: null

    }
    const [isEditMode] = useState(unitId ? true : false);
    const [formInitialValues, setFormInitialValues] = useState(initialValues);
    const [propertyUnitsImagesData, setPropertyUnitsImagesData] = useState<Images[]>([]);
    const [leaseEndDate, setLeaseEndDate] = useState<Date | null>(null);
    const [leaseStartDate, setLeaseStartDate] = useState<Date | null>(null);
    const [selectedTenant, setSelectedTenant] = useState<string>('');

    useEffect(() => {
        dispatch(setActivePageHeader(isEditMode ? 'Edit Unit' : 'Create Unit'));
        if (propertiesList.length <= 0) {
            getPropertyData();
        }
        getTenantsData();
        if (isEditMode) {
            processEditFormData();
        }
    }, []);

    const getPropertyData = async () => {
        const response = await propertiesService.getPropertyList();
        if (response.results && response.results.length > 0) {
            dispatch(savePropertiesListData(response.results, response.count));
        }
    }

    const getTenantsData = async () => {
        const response = await propertiesService.getDisplayTenants();
        if (response.results && response.results.length > 0) {
            setTenantsData(response.results)
        }
    }

    const processEditFormData = async () => {
        const response = await propertiesService.getPropertyUnitById(unitId);
        if (axios.isAxiosError(response)) {
            if (response.response?.data?.detail) {
                ErrorToast(response.response?.data?.detail || "Error in get unit")
            } else {
                ErrorToast("Error in get unit");
            }
        } else {
            const selectedUnityData = response;
            setPropertyUnitsImagesData
            setPropertyUnitsImagesData(selectedUnityData.images);
            setLeaseStartDate(
                selectedUnityData.lease_start_date ? new Date(selectedUnityData.lease_start_date) : null,
            )
            setLeaseEndDate(

                selectedUnityData.lease_end_date ? new Date(selectedUnityData.lease_end_date) : null,
            )
            setSelectedTenant(selectedUnityData.assigned_to?.id.toString() || '');
            setFormInitialValues({
                unit_name: selectedUnityData.unit_name,
                area_size: selectedUnityData.area_size,
                monthly_rent: selectedUnityData.monthly_rent,
                rent_due_day: selectedUnityData.rent_due_day,
                features: selectedUnityData.features,
                description: selectedUnityData.description,
                property: selectedUnityData.property,
                tenant: selectedUnityData.assigned_to?.id,
                lease_start_date: selectedUnityData.lease_start_date,
                lease_end_date: selectedUnityData.lease_end_date
            })
        }
    }

    // eslint-disable-next-line
    const handleSubmit = async (formValues: any) => {
        const formdata = new FormData();
        const objKeys = Object.keys(formValues) as Array<keyof AddUnitFormStates>
        objKeys.forEach((key) => {
            if (key === "tenant" && !formValues[key] || key === "lease_start_date" && !formValues["tenant"] || key === "lease_end_date" && !formValues["tenant"]) {
                return;
            }
            if (Array.isArray(formValues[key as keyof AddUnitFormStates])) {
                formValues[key as keyof AddUnitFormStates].forEach((arr_item: string) => {
                    formdata.append(key, arr_item);
                })
            } else {
                formdata.append(key, formValues[key]);
            }

        });
        files.length && files.forEach((_file, index) => {
            formdata.append(`images[${index}]image`, files[index]);
        });
        try {
            if (isEditMode) {
                const response: any = await propertiesService.editPropertyUnit(formdata, unitId);
                if (response.success === false) {
                    setShowOutOfUnitConnectsModal(true);
                } else {
                    navigate(ROUTES.UNITS);
                }

            } else {
                await propertiesService.savePropertyUnit(formdata);
                navigate(ROUTES.UNITS);
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.data?.detail) {
                    if (isEditMode) {
                        ErrorToast(err.response?.data?.detail || "Error in edit property unit")
                    } else {
                        ErrorToast(err.response?.data?.detail || "Error in add new property unit")
                    }
                } else {
                    ErrorToast(isEditMode ? "Error in edit property unit" : "Error in add new property unit");
                }
            }
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const filesData = target.files ? Array.from(target.files) : [];
        if (filesData.length) {
            let uploadFiles = files.slice();
            uploadFiles = uploadFiles.concat(filesData);
            if (uploadFiles.length > 5) {
                ErrorToast("Maximum 5 files can be uploaded");
                return false;
            } else {
                setFiles(uploadFiles);
            }
        }
    }

    const handleSavedFileDelete = async (imgId: number) => {
        let savedImages = propertyUnitsImagesData.slice();
        savedImages = savedImages.filter(data => data.id !== imgId);
        try {
            const url = API_URLS.PROPERTY_UNIT_DELETE_IMAGE.replace('{id}', imgId.toString())
            await propertiesService.deleteSavedUploadedImage(url);
            setPropertyUnitsImagesData(savedImages);
            SuccessToast("Image deleted successfully");
        } catch (err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.data?.detail) {
                    ErrorToast(err.response?.data?.detail || "Error in delete images")
                } else {
                    ErrorToast("Error in delete images");
                }
            }
        }
    }

    const handleFileDelete = (index: number) => {
        const uploadFiles = files.slice();
        uploadFiles.splice(index, 1);
        setFiles(uploadFiles);
    }

    return (
        <>
            <div className="col-12 col-sm-10">
                <h2 className="title mb-5">{isEditMode ? "Edit a unit" : "Create a unit"}</h2>
                <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    onSubmit={(values: AddUnitFormStates) => { handleSubmit(values) }}
                    validationSchema={() => addUnitsValidationSchema()}
                >
                    {({
                        errors,
                        values,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        dirty,
                        isValid
                    }) => (
                        <Form>
                            <fieldset>
                                <legend>Main information</legend>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup className="mb-4">
                                            <Label>
                                                Name<span className="required">*</span>
                                            </Label>
                                            <Input
                                                id="unit_name"
                                                name="unit_name"
                                                placeholder="Type a Unit Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.unit_name}
                                                required
                                            />
                                            {touched.unit_name && errors.unit_name && (
                                                <span className='error-text text-danger'>{errors.unit_name}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                Property<span className="required">*</span>
                                            </Label>
                                            <Input
                                                id="property"
                                                name="property"
                                                type="select"
                                                bsSize="lg"
                                                value={values.property}
                                                onBlur={handleBlur}
                                                onChange={(e) => setFieldValue("property", e.target.value)}
                                            >
                                                <>
                                                    <option disabled value={0}>Choose a Property</option>
                                                    {propertiesList.map((property, index) => <option key={index} value={property.id}>{property.title}</option>)}
                                                </>

                                            </Input>
                                            {touched.property && errors.property && (
                                                <span className='error-text text-danger'>{errors.property}</span>
                                            )}
                                        </FormGroup>

                                    </Col>
                                </Row>
                                <Row>

                                    <Col md={6}>
                                        <FormGroup className='select-input'>
                                            <Label>
                                                Tenant
                                            </Label>
                                            {values.tenant && (
                                                <a
                                                    onClick={() => {
                                                        setFieldValue("tenant", null)
                                                        setSelectedTenant('')
                                                        setLeaseStartDate(null)
                                                        setLeaseEndDate(null)
                                                        setFieldValue('lease_start_date', null)
                                                        setFieldValue('lease_end_date', null)
                                                    }}
                                                >
                                                    <img src={clearIcon} className='clear-icon cursor-pointer' />
                                                </a>
                                            )}
                                            <Input
                                                id="tenant"
                                                name="tenant"
                                                type="select"
                                                bsSize="lg"
                                                value={selectedTenant}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    setSelectedTenant(e.target.value)
                                                    setFieldValue("tenant", parseInt(e.target.value))

                                                }}
                                            >
                                                <>
                                                    <option disabled value={''}>Please Assign a Tenant</option>
                                                    {tenantsData.map((tenant: any, index: any) => <option key={index} value={tenant.id}>{tenant.display_name}</option>)}
                                                </>

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup className="mb-4">
                                            <Label>
                                                Monthly Rent<span className="required">*</span>
                                            </Label>
                                            <div className="position-relative">
                                                <Input
                                                    id="monthly_rent"
                                                    name="monthly_rent"
                                                    placeholder="Monthly Rent"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text"
                                                    value={values.monthly_rent}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    required
                                                />
                                                {touched.monthly_rent && errors.monthly_rent && (
                                                    <span className='error-text text-danger'>{errors.monthly_rent}</span>
                                                )}
                                                <span className="input-info">USD</span>
                                            </div>
                                        </FormGroup>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Tooltip target=".tooltip-rent-due-day">
                                                Please note that if you Choose the 31st <br />
                                                day of a month with only 28 or 30 days, <br />
                                                the payment will be scheduled for the <br />
                                                last day of the month.
                                            </Tooltip>
                                            <Label>
                                                Rent Due Day<span className="required">*</span> <img src={infoIcon} alt="info-icon" className="tooltip-rent-due-day" />
                                            </Label>
                                            <div className="position-relative">
                                                <Input
                                                    id="rent-due-date"
                                                    name="rent_due_day"
                                                    type="number"
                                                    placeholder="Rent Due Day"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.rent_due_day}
                                                    min={1}
                                                    max={31}
                                                    required
                                                />
                                                {touched.rent_due_day && errors.rent_due_day && (
                                                    <span className='error-text text-danger'>{errors.rent_due_day}</span>
                                                )}
                                                <span className="input-info">of each month</span>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup className="mb-4">
                                            <Label>
                                                Unit Size<span className="required">*</span>
                                            </Label>
                                            <div className="position-relative">
                                                <Input
                                                    id="area_size"
                                                    name="area_size"
                                                    placeholder="Choose Area Size"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.area_size}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    required
                                                />
                                                {touched.area_size && errors.area_size && (
                                                    <span className='error-text text-danger'>{errors.area_size}</span>
                                                )}
                                                <span className="input-info">Sq ft</span>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {values.tenant && (
                                    <Row>
                                        <Col md={6} className='mt-3'>
                                            <FormGroup className='mb-4'>
                                                <Label for='lease_start_date'>Lease Start Date</Label>
                                                <Calendar
                                                    showIcon
                                                    value={leaseStartDate}
                                                    onChange={(e: any) => {
                                                        setLeaseStartDate(e.value);
                                                        setFieldValue('lease_start_date', moment(e.value).format('yyyy-MM-DD').toString())
                                                    }}
                                                    placeholder='Select a date'
                                                    name='lease_start_date'
                                                    minDate={new Date()}
                                                    required={values.tenant ? true : false}
                                                    dateFormat='yy-mm-dd'
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className='mt-3'>
                                            <FormGroup className='mb-4'>
                                                <Label for='lease_end_date'>Lease End Date</Label>
                                                <Calendar
                                                    showIcon
                                                    value={leaseEndDate}
                                                    onChange={(date: any) => {
                                                        setLeaseEndDate(date.value);
                                                        setFieldValue('lease_end_date', moment(date.value).format('yyyy-MM-DD').toString())
                                                    }}
                                                    placeholder='Select a date'
                                                    name='lease_end_date'
                                                    required={values.tenant ? true : false}
                                                    minDate={values.lease_start_date ? moment(leaseStartDate).add(1, 'days').toDate() : new Date()}
                                                    dateFormat='yy-mm-dd'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                            </fieldset>
                            <fieldset>
                                <legend>Details</legend>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <Label className="mb-0">
                                                Features<span className="required">*</span>
                                            </Label>
                                            <Button className="btn flat" onClick={() => setShowAddAdvantageModal(true)} >Add feature</Button>
                                        </FormGroup>
                                    </Col>
                                    <div className="features-items">
                                        {values.features.length ? values.features.map((feature, index) => (
                                            <div className="feature-card" key={index}>
                                                <span className="feature-text">{feature}</span>
                                                <img src={trashIcon} alt="trash icon" onClick={() => { values.features.splice(index, 1); setFieldValue("features", values.features) }} />
                                            </div>
                                        )) : ''}
                                    </div>
                                    {touched.features && errors.features && (
                                        <span className='error-text text-danger'>{errors.features}</span>
                                    )}
                                </Row>
                                <FormGroup>
                                    <Label>
                                        Description
                                    </Label>
                                    <Input
                                        id="description"
                                        name="description"
                                        type="textarea"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                        placeholder="Enter a unit description"
                                        maxLength={400}
                                    />
                                    <span className="input-group-caption">Maximum of 400 characters</span>
                                </FormGroup>
                                <FormGroup className="upload-image-section pt-2">
                                    <Label>
                                        Upload Images of your Property
                                    </Label>
                                    <UploadImages files={files} handleFileChange={handleFileChange} handleFileDelete={handleFileDelete} imagesData={propertyUnitsImagesData} isEditFormMode={isEditMode ? true : false} handleSavedFileDelete={handleSavedFileDelete} />
                                </FormGroup>
                            </fieldset>
                            <div className="submit-wrapper">
                                <Button type="submit" className="btn cta" disabled={!dirty || !isValid}>{isEditMode ? "Edit unit" : "Create unit"}</Button>
                                <Button className="btn simple" onClick={() => navigate(ROUTES.UNITS)}>Cancel</Button>
                            </div>
                            <NewAdvantageModal
                                isModalOpen={showAddAdvantageModal}
                                handleClose={(e?: AddNewAdvantageProperty) => {
                                    if (e?.name) {
                                        const featuresData: string[] = values.features.slice();
                                        featuresData.push(e.name);
                                        setFieldValue("features", featuresData);
                                        SuccessToast("Unit feature created")
                                    }
                                    setShowAddAdvantageModal(false);
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </div >

            {showOutOfUnitConnectsModal && (
                <CommonAlertModal
                    isModalOpen={showOutOfUnitConnectsModal}
                    handleClose={() => setShowOutOfUnitConnectsModal(false)}
                    message='You’re out of unit connects'
                    subHeading='Upgrade your plan to add more units.'
                    bottonText='Upgrade Plan'
                    modalIcon={cartFilledIcon}
                    handleButtonAction={() => {
                        navigate(ROUTES.SUBSCRIPTIONS)
                        setShowOutOfUnitConnectsModal(false)
                    }}
                />
            )
            }
        </>
    )
}

export default AddUnits;