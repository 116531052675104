import axios from 'axios';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Col, Row, FormGroup, Input, Label } from 'reactstrap';
import companyLogo from '../../assets/images/company-logo.png';
import { API_URLS } from '../../constants/appConstants';
import API from '../../helpers/api';
import { ErrorToast, SuccessToast } from '../../helpers/toster';
import * as Yup from 'yup';
import { passRecoveryFormStates } from '../../types/auth';

import PrivacyTerms from '../../components/common/PrivacyTerms/component';

const initialValues = {
    email: '',
}

const PassRecoveryValidationSchema = () =>
    Yup.object().shape({
        email: Yup.string().required('Email is required*').email('Invalid email')
    })

const PasswordRecovery = () => {

    const handleRecoveryEmail = async (formValues: passRecoveryFormStates) => {
        try {
            const response = await API.post(API_URLS.REQUEST_RESET_PASSWORD_EMAIL, formValues);
            if (response.status === 201 || response.status === 200) {
                SuccessToast("We have sent you an email to reset your password.");
            } else {
                ErrorToast("Error while sending email. Please try after some time");
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.data.email) {
                    ErrorToast(error.response?.data.email[0]);
                    return false;
                }
                if (error.response?.data.password) {
                    ErrorToast(error.response?.data.password[0]);
                    return false;
                }
            }
            ErrorToast("Invalid request");
        }
    }
    const handleSubmit = (formData: passRecoveryFormStates) => {
        handleRecoveryEmail(formData);
    }

    return (
        <div className="container-fluid password-reset">
            <Row className="h-100">
                <Col className="password-page-companyinfo" md={4}>
                    <div className="company-logo">
                        <img src={companyLogo} />
                    </div>
                    <div className="multipurpose-tool-text mt-3">Multipurpose Tool to Succeed Your Property Business</div>
                    <div className="having-trouble">Having troubles? <span className="get-help">Get Help</span></div>
                </Col>
                <Col md={8} className="d-flex justify-content-center align-items-center position-relative flex-column">
                    <div className="form-wrapper">
                        <div className="heading">Password recovery</div>
                        <div className="form-card-container pt-2">
                            <div className="form-card-heading">Forgot password?</div>
                            <div className="sub-heading pt-2">No worries! Just enter your email and we’ll send you a reset password link.</div>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values: passRecoveryFormStates) => handleSubmit(values)}
                                validationSchema={() => PassRecoveryValidationSchema()}>
                                {({
                                    errors,
                                    values,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    dirty,
                                    isValid
                                }) => (
                                    <Form>
                                        <>
                                            <FormGroup className="mb-4 mt-4">
                                                <div className="boxlabel-input-container">
                                                    <Label for="email">Email address</Label>
                                                    <Input type="email" name="email" placeholder="Email address" onChange={handleChange} value={values.email} onBlur={handleBlur} required />
                                                </div>
                                                {touched.email && errors.email && (
                                                    <span className='error-text text-danger'>{errors.email}</span>
                                                )}
                                            </FormGroup>
                                            <Button className="submit-btn" block type="submit" disabled={!dirty || !isValid}>Send Recovery Email</Button>
                                        </>
                                    </Form>
                                )}
                            </Formik>
                            <div className="informative-content text-center">Just remember? <Link className="link-content" to="/">Sign in</Link></div>
                        </div>
                    </div>
                    <PrivacyTerms />
                </Col>
            </Row>
        </div>
    )
}

export default PasswordRecovery;