import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormGroup } from 'reactstrap';
import { FloatingInputField } from '../common/FloatingInputField/FloatingInputField';
import eyeIcon from '../../assets/images/svgImages/eye_icon.svg';
import closeEyeIcon from '../../assets/images/svgImages/close_eye_icon.svg';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { loginFormStates } from '../../types/auth';
import { ROUTES } from '../../constants/routesConstant';

export interface loginFormProps {
    handleSubmit: (loginFormStates: loginFormStates) => void;
}

const initialValues = {
    email: '',
    password: '',
    portal: "tenant"
}

const signInValidationSchema = () =>
    Yup.object().shape({
        email: Yup.string().required('Email is required*').email('invalid email'),
        password: Yup.string().required("Password is required*"),
    })

const SignInForm = (props: loginFormProps) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isTenant, setIsTenant] = useState(true);

    const handleSubmit = (formData: loginFormStates) => {
        formData.portal = isTenant ? "tenant" : "landlord";
        props.handleSubmit(formData);
    }

    return (
        <div className="login-form">
            <div className="login-tab">
                <div className="login-tab-container">
                    <button className={isTenant ? 'login-tab-button login-tab-button--active' : 'login-tab-button'} onClick={() => setIsTenant(true)}>Tenant account</button>
                    <button className={!isTenant ? 'login-tab-button login-tab-button--active' : 'login-tab-button'} onClick={() => setIsTenant(false)}>Landlord account</button>
                </div>
            </div>

            <h3 className="form-heading">Sign in</h3>
            <div className="informative-content">New user? <Link className="link-content" to="signup">Create an account.</Link></div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values: loginFormStates) => handleSubmit(values)}
                validationSchema={() => signInValidationSchema()}>
                {({
                    errors,
                    values,
                    touched,
                    handleBlur,
                    handleChange,
                    dirty,
                    isValid
                }) => (
                    <Form>
                        <FormGroup className="my-4">
                            <FloatingInputField name="email" label={'Email address'} type={'text'} onChange={handleChange} value={values.email} isRequired={true} onBlur={handleBlur} />
                            {touched.email && errors.email && (
                                <span className='error-text text-danger'>{errors.email}</span>
                            )}
                        </FormGroup>
                        <FormGroup className="mb-4 position-relative">
                            <FloatingInputField name="password" label={'Password'} type={isShowPassword ? 'text' : 'password'} onChange={handleChange} value={values.password} isRequired={true} onBlur={handleBlur} />
                            <img alt="eye-icon" className="img-icon" src={isShowPassword ? eyeIcon : closeEyeIcon} onClick={() => setIsShowPassword(prevProp => !prevProp)} />
                            {touched.password && errors.password && (
                                <span className='error-text text-danger'>{errors.password}</span>
                            )}
                        </FormGroup>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="forgot-pwd-link"><Link to={ROUTES.PASSWORD_RECOVERY}>Forgot Password?</Link></div>
                            <Button className="btn cta" type="submit" disabled={!dirty || !isValid}>Sign In</Button>
                        </div>
                        <div className="divider"></div>
                        <div className="secutity-info">Protected by reCAPTCHA and the Google <span className="app-links">Privacy Policy</span> and <span className="app-links">Terms of Service</span> apply.</div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SignInForm;