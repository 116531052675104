import React, { useEffect, useState } from 'react'

import KeyboardDoubleArrowLeft from '../../../assets/images/svgImages/KeyboardDoubleArrowLeft.svg'
import edit from '../../../assets/images/svgImages/Edit.svg'
import trash from '../../../assets/images/svgImages/trash.svg'

interface DetailViewProps {
  children: React.ReactNode
  deteleButton?: () => void
  editButton?: () => void
  onCloseView?: any
  closeButton: boolean
}


const DetailView = (props: DetailViewProps) => {
  const [closeView, setCloseView] = useState(false)

  useEffect(() => {
    setCloseView(props.closeButton)
  }, [props.closeButton])

  return (
    <aside className={`${closeView ? 'opened ' : 'closed '}task-details`}>
      <header>
        <div className='close-options'>
          <button className='btn btn-for-input' onClick={() => {
            setCloseView(false)
            props.onCloseView(false)
          }}>
            <img src={KeyboardDoubleArrowLeft} />
          </button>
        </div>
        <div className='admin-options'>
          {props.editButton && (
            <button className='btn btn-for-input'
              onClick={props.editButton}
            >
              <img src={edit} />
            </button>)
          }
          {props.deteleButton && (
            <button className='btn btn-for-input'
              onClick={props.deteleButton}
            >
              <img src={trash} />
            </button>
          )}
        </div>
      </header>
      <div className='details'>
        {props.children}
      </div>
    </aside>
  )
}

export default DetailView