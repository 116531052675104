import { Button, Form, Input, Label, Modal, ModalBody } from 'reactstrap'
import { ChangeEvent, useEffect, useState } from 'react'
import closeIcon from '../../../assets/images/svgImages/Close.svg'
import starIcon from '../../../assets/images/svgImages/Star.svg'
import { AddNewAdvantageProperty } from '../../../types/properties'

interface NewAdvantageModalProps {
  isModalOpen: boolean
  handleClose: (newAdvantage?: AddNewAdvantageProperty) => void
}

const NewAdvantageModal = (props: NewAdvantageModalProps) => {
  const { isModalOpen, handleClose } = props
  const [advantageName, setAdvantageName] = useState<string>('')

  const handleSaveModal = () => {
    const newAdvantage: AddNewAdvantageProperty = {
      name: advantageName,
    }
    handleClose(newAdvantage)
    setAdvantageName('')
  }

  useEffect(() => {
    setTimeout(() => {
      if (isModalOpen) {
        const input = document.getElementById("featureInput");
        input?.focus()
      }
    }, 100)
  }, [isModalOpen])

  return (
    <Modal
      size='md'
      centered
      className='common-alert-modal custom-recurrence-modal'
      isOpen={isModalOpen}
      toggle={() => handleClose()}
    >
      <div className='modal-header-container'>
        <div className='close-icon text-end cursor-pointer' onClick={() => {
          handleClose()
          setAdvantageName('')
        }}>
          <img src={closeIcon} />
        </div>
      </div>
      <ModalBody className='common-body-container'>
        <div className="icon-wrapper mb-4"><img src={starIcon} alt="star icon" className='icon' /></div>
        <div className='header-heading mb-4'>Create property feature</div>
        <Form className='mb-4'>
          <Label>Name<span className="required">*</span></Label>
          <Input
            type='text'
            id='featureInput'
            placeholder='Enter a property feature name'
            value={advantageName}
            maxLength={20}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAdvantageName(e.target.value)
            }
            autoFocus={true}
          />

          <span className="input-group-caption">Maximum of 20 characters</span>
        </Form>
        <div className='modal-button d-flex justify-content-start mt-3'>
          <Button
            className='btn cta me-2'
            disabled={
              !advantageName
            }
            onClick={() => handleSaveModal()}>
            Create
          </Button>
          <Button
            className='btn flat'
            onClick={() => {
              const emptyAdvantage: AddNewAdvantageProperty = {
                name: '',
              }
              handleClose(emptyAdvantage)
              setAdvantageName('')
            }}
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default NewAdvantageModal
