import noChatImage from '../../assets/images/verify_email.png'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { useAppDispatch } from '../../hooks/storeHook'
import { Button } from 'reactstrap'
import { useEffect, useState } from 'react'
import { chatServices } from '../../helpers/chatService'
import NewChatModal from '../../components/common/NewChatModal/NewChatModal'
import Chats from './components/chats'

const Chat = () => {
  const dispatch = useAppDispatch()
  const [showNewChatModal, setShowNewChatModal] = useState(false)
  const [chatList, setChatList] = useState([])
  const [recipientUser, setRecipientUser] = useState(0)

  useEffect(() => {
    dispatch(setActivePageHeader('Chat'))
    getChatList()
  }, [])

  const getChatList = async () => {
    const response = await chatServices.getChatList()
    setChatList(response)
  }

  return (
    <>
      {chatList.length > 0 || recipientUser !== 0 ? (
        <Chats recipientId={recipientUser} firstChat={recipientUser ? true : false} />
      ) : (
        <div className='no-chats-container'>
          <img src={noChatImage} alt='create new chat' />
          <p className='no-prop'>{'No chats here yet'}</p>
          <p className='add-prop'>{'Send a Message to Begin Communication'}</p>
          <Button className='btn simple' onClick={() => setShowNewChatModal(true)}>
            Send Message
          </Button>
        </div>
      )}

      {showNewChatModal && (
        <NewChatModal
          isModalOpen={showNewChatModal}
          handleClose={() => {
            setShowNewChatModal(false)
          }}
          bottonText='Go Back'
          handleButtonAction={(value) => {
            setShowNewChatModal(false)
            setRecipientUser(value)
          }}
        />
      )}
    </>
  )
}

const ChatView = () => {
  return <Chat />
}

export default ChatView
