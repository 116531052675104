import { useEffect, useRef } from 'react'
import useWebSocket from 'react-use-websocket'
import axios from 'axios'

import { Button } from 'reactstrap'
import { OverlayPanel } from 'primereact/overlaypanel';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { setCurrentUserInfo, setNotificationsData } from '../../store/auth/authSlice'
import { getUser, isLoggedIn, isTokenExpired } from '../../helpers/userService'
import { settingService } from '../../helpers/settingService'
import { ErrorToast } from '../../helpers/toster'
import { PersonalProfile } from '../../types/setting'
import { WS_URLS } from '../../constants/appConstants'
import { BackButton } from '../../components/common/BackButton/BackButton'


import notificationCancelIcon from '../../assets/images/svgImages/notification_close_ic.svg'
import bellIcon from '../../assets/images/svgImages/Bell.svg'
import bellNotificationIcon from '../../assets/images/svgImages/Bell-notification.svg'

const Header = () => {
  const DropdownRef = useRef<any>(null)

  const dispatch = useAppDispatch()
  const isUserLoggedIn = isLoggedIn()
  const userData = getUser()

  let initialValue: PersonalProfile = {
    first_name: '',
    last_name: '',
    note: '',
    phone: '',
    old_password: '',
    new_password: '',
    avatar: {} as File,
    role: '',
    portal: '',
    confirmNewPassword: '',
    email: '',
  }

  const { activePageHeaderText, userInfo, notificationData } = useAppSelector(
    (state) => state.authReducer,
  )

  const WS_MAINTENANCE =
    process.env.REACT_APP_WEBSOCKET + WS_URLS.MAINTENANCE_NOTIFICATIONS + userData?.id + '/'
  const { lastMessage: lastNotificationMaintenance } = useWebSocket(WS_MAINTENANCE, {
    shouldReconnect: () => true,
  })

  const WS_TODO = process.env.REACT_APP_WEBSOCKET + WS_URLS.TODO_NOTIFICATIONS + `${userData?.id}/`
  const { lastMessage: lastNotificationTodo } = useWebSocket(WS_TODO, {
    shouldReconnect: () => true,
  })

  const WS_LEASE_END = process.env.REACT_APP_WEBSOCKET + WS_URLS.LEASE_END_NOTIFICATIONS
  const { lastMessage: lastNotificationLeaseEnd } = useWebSocket(WS_LEASE_END, {
    shouldReconnect: () => true,
  })

  const WS_CHAT = process.env.REACT_APP_WEBSOCKET + WS_URLS.CHAT_NOTIFICATIONS + `${userData?.id}/`
  const { lastMessage: lastNotificationChat } = useWebSocket(WS_CHAT, {
    shouldReconnect: () => true,
  })

  useEffect(() => {
    if (isUserLoggedIn) {
      getUserDetail()
      getNotifications()
    }
    console.log(notificationData)
  }, [
    lastNotificationMaintenance,
    lastNotificationLeaseEnd,
    lastNotificationTodo,
    lastNotificationChat,
  ])

  useEffect(() => {
    const tokenVerification = setInterval(() => {
      isTokenExpired()
    }, 20000);

    return () => clearInterval(tokenVerification);
  }, []);

  const getUserDetail = async () => {
    const response = await settingService.getUserDetails()
    initialValue = {
      ...initialValue,
      ...response,
      first_name:
        (response.first_name || response.first_name_by_ll) +
        ' ' +
        (response.last_name || response.last_name_by_ll),
    }
    dispatch(setCurrentUserInfo(response))
  }

  const getNotifications = async () => {
    const response = await settingService.getNotificationsData()
    dispatch(setNotificationsData(response))
  }

  const markAsReadNotificationItem = async (id: string) => {
    const res = await settingService.markAsReadNotification(id)
    if (axios.isAxiosError(res)) {
      if (res.response?.data?.detail) {
        ErrorToast(res.response?.data || 'Error in read notification')
        return false
      } else {
        ErrorToast('Error in read notification')
        return false
      }
    }
    getNotifications()
  }

  return (
    <div className='header-wrapper'>
      <nav className='navbar navbar-expand-lg header-container'>
        <div className='container-fluid'>
          <div className='d-flex align-items-center gap-1'>
            <BackButton />
            <h4 className='mb-0 active-page-name'>{activePageHeaderText}</h4>
          </div>
          <div className='d-flex justify-content-end align-items-center'>
            <div className='mx-4'>
              <Button className={`btn flat`} type='button' onClick={(e) => DropdownRef.current.toggle(e)}>
                <img src={notificationData.length > 0 ? bellNotificationIcon : bellIcon} alt='bell-icon' />
              </Button>
              <OverlayPanel className='dropdown-list dropdown-header' ref={DropdownRef}>
                <div className='notifications-items '>
                  {notificationData.length > 0 &&
                    notificationData.map((notification, index) => (
                      <>
                        <div className='notification-item' key={index}>
                          <div
                            className='close-icon w-100 d-flex justify-content-end'
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              markAsReadNotificationItem(notification.id.toString())
                            }}
                          >
                            <img src={notificationCancelIcon} />
                          </div>
                          <p>{notification.title}</p>
                        </div>
                      </>
                    ))}

                  {!notificationData.length && (
                    <div className='p-3'>No New Notifications</div>
                  )}
                </div>
              </OverlayPanel>
            </div>

            <div className='d-flex align-items-center'>
              <div className='profile-pic'>
                {userInfo.avatar && (
                  <img className='w-100 h-100 rounded' src={userInfo.avatar as string} />
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
