import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

import { Button, Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHook";
import { setActivePageHeader } from "../../store/auth/authSlice";
import { setMaintenanceListData } from "../../store/tenant/tenantSlice";
import { ROUTES } from "../../constants/routesConstant";
import { tenantService } from "../../helpers/tenantService";
import NoListingView from "../../components/common/NoListingView/NoListingView";
import { CountCard } from '../../components/common/CountCard/CountCard'
import TenantMaintenanceListPageComponent from "./TenantMaintenanceListPage";

import searchIcon from "../../assets/images/svgImages/search_icon.svg";
import clearIcon from '../../assets/images/svgImages/clear_input_icon.svg'
import noListMaintenance from '../../assets/images/svgImages/no-list-maintenance.svg'

const TenantMaintenance = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<string>("");
    const [cardData, setCardData] = useState({
        total: 0,
        completed: 0,
        progress: 0,
    })



    const { tenantMaintenanceList, totalMaintenanceListCout } = useAppSelector((state) => state.tenantReducer)


    useEffect(() => {
        if (filters) {
            const filteredData = tenantMaintenanceList.filter((data) => data.title.toLowerCase().includes(filters.toLowerCase()))
            dispatch(setMaintenanceListData(filteredData, filteredData.length))
        } else {
            getMaintenanceTask()
        }
    }
        , [filters])

    useEffect(() => {
        dispatch(setActivePageHeader('Maintenace'))
        getMaintenanceTask()
        getCardData()
    }, [])



    const getMaintenanceTask = async () => {
        const response = await tenantService.getTenantMaintenanceTasks();
        if (response) {
            dispatch(setMaintenanceListData(response.results, response.count))
            console.log(response.results, "response")
        }
    }

    const getCardData = async () => {
        const total = tenantMaintenanceList.length
        const completed = tenantMaintenanceList.filter((x) => x.status_name === 'Completed').length
        const progress = tenantMaintenanceList.filter((x) => x.status_name === 'In-progress').length
        setCardData({
            total: total,
            completed: completed,
            progress: progress,
        })
    }
    return (
        <>
            <Row className="pb-4">
                <Col><h2 className="title">Maintenance</h2></Col>
                <Col md={8} className="d-flex align-items-center justify-content-end">
                    <Button className="btn cta" onClick={() => { navigate(ROUTES.TENANT_MAINTENANCE_CREATE) }} >Create Task</Button>
                </Col>
            </Row>
            <Row className='pb-4 w-100 d-flex gap-3'>

                <CountCard
                    heading='Total Opened'
                    subHeading={moment().format('MMMM')}
                    count={cardData.total}
                />
                <CountCard
                    heading='Total Completed'
                    subHeading={moment().format('MMMM')}
                    count={cardData.completed}
                />
                <CountCard
                    heading='Total In-progress'
                    subHeading={moment().format('MMMM')}
                    count={cardData.progress}
                />
            </Row>
            <Row className="pb-4">
                <Col md={4}>
                    <div className='searchbar'>
                        <div className='searchbar-container searchbar-container--bg me-2'>
                            <input className='form-control' placeholder='Search by unit name' value={filters} onChange={(e) => {
                                setFilters(e.target.value)
                            }} />
                            <div className='search-icon cursor-pointer'>
                                <img src={searchIcon} alt='search-icon' />
                            </div>
                            {filters && (
                                <a
                                    onClick={() => {
                                        setFilters('')
                                    }}
                                >
                                    <img src={clearIcon} className='search-clear cursor-pointer' />
                                </a>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>

            {tenantMaintenanceList?.length ?
                <TenantMaintenanceListPageComponent refreshTable={getMaintenanceTask} tenantMaintenanceList={tenantMaintenanceList} totalMaintenanceListCout={totalMaintenanceListCout} /> :
                <NoListingView
                    image={noListMaintenance}
                    noPropText="No tasks"
                    addPropText="You have not yet created any tasks."
                    onClick={() => navigate(ROUTES.TENANT_MAINTENANCE_CREATE)}
                    buttonText="Create Task"
                />
            }
        </>
    )
}
export default TenantMaintenance;