export interface inputFieldProps {
  value?: string;
  label?: string;
  name?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = (props: inputFieldProps) => {
  return (
    <div className="form-check">
        <input className="form-check-input" type="checkbox" checked={props.checked} onChange={props.onChange} />
        <label className="form-check-label secutity-info ps-2">
            By clicking Create account, I agree that I have read and accepted the <a className="app-links" href="">Terms of Use</a> and <a className="app-links" href="">Privacy Policy</a>.
        </label>
    </div>
  );
};
export default Checkbox;