import { Button, Card, CardBody, CardHeader, Col, List, ListInlineItem, Row } from "reactstrap";
import ImageGallery from "../../components/common/ImageGallery/ImageGallery";
import { useAppSelector } from "../../hooks/storeHook";
import searchIcon from '../../assets/images/svgImages/search_icon.svg'
import locationIcon from '../../assets/images/location-icon.png'
import { useNavigate } from "react-router";
import { ROUTES } from "../../constants/routesConstant";
import { tenantService } from "../../helpers/tenantService";
import { SuccessToast } from "../../helpers/toster";

const TenantMaintenanceDetailComponent = () => {


  const { selectedTenantMaintenance } = useAppSelector((state) => state.tenantReducer)
  const navigate = useNavigate();

  const processDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  }

  const editMaintenance = () => {
    navigate(`${ROUTES.TENANT_MAINTENANCE_EDIT}?id=${selectedTenantMaintenance?.id}`)
  }

  const removeSelectedTask = () => {
    tenantService.deleteSelectedMaintenance(selectedTenantMaintenance?.id.toString()).then(() => {
      navigate(ROUTES.TENANT_MAINTENANCE)
      SuccessToast('Task Deleted Successfully')
    }).catch((err) => {
      console.error(err)
    })
  }

  return (
    <>
      <Row className='pb-4'>
        <Col md={4}>
          {/* <div className='searchbar-container me-4'>
                <input className='form-control' placeholder='Search here...' />
                <div className='search-icon cursor-pointer'>
                  <img src={searchIcon} alt='search-icon' />
                </div>
              </div> */}
        </Col>
        <Col md={8} className="d-flex align-items-center justify-content-end">
          <Button className="btn outline-btn" onClick={() => removeSelectedTask()} >Remove Task</Button>
          <Button className="btn add-new-item-btn ms-4" >Mark as Complete</Button>
        </Col>
      </Row>
      <Card className='form-card'>
        <CardHeader>Maintenance Detail</CardHeader>
        <CardBody>
          <p className='detail'>Details</p>
          <List type='inline'>
            <ListInlineItem>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={locationIcon} alt='' />
                <div className='detail-item'>
                  <p>
                    Task Name <span>| {selectedTenantMaintenance?.title}</span>
                  </p>
                </div>
              </div>
            </ListInlineItem>

            <ListInlineItem>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={locationIcon} alt='' />
                <div className='detail-item'>
                  <p>
                    Status <span>| {selectedTenantMaintenance?.status_name}</span>
                  </p>
                </div>
              </div>
            </ListInlineItem>

            <ListInlineItem>
              <div className='d-flex align-items-center justify-content-center'>
                <img src={locationIcon} alt='' />
                <div className='detail-item'>
                  <p>
                    Date
                    <span>
                      |{' '}
                      {processDate(selectedTenantMaintenance?.created)}
                    </span>
                  </p>
                </div>
              </div>
            </ListInlineItem>
          </List>

          <p className='detail'>Description</p>
          <p>{selectedTenantMaintenance?.description}</p>

          <ImageGallery imagesData={selectedTenantMaintenance?.images || []} />

          <Row className='pb-4 mt-5'>
            <Col md={4}></Col>
            <Col md={8} className='d-flex align-items-center justify-content-end'>


              <Button className='btn add-new-item-btn ms-4' onClick={editMaintenance} >
                Edit
              </Button>

            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default TenantMaintenanceDetailComponent;