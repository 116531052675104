import { useEffect, useRef, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import { useNavigate } from 'react-router-dom'
import axios, { isAxiosError } from 'axios'
import moment from 'moment'

import { Container, Row, Col, Button } from 'reactstrap'
import { Badge } from 'primereact/badge'
import { classNames } from 'primereact/utils'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Dropdown from '../../../components/common/Dropdown/Dropdown'
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator'
import {
  ToDoTasksListData,
  PropertyUnitDetail,
} from '../../../types/todoTasks'
import { ROUTES } from '../../../constants/routesConstant'
import { WeekDaysValuesNames, WS_URLS } from '../../../constants/appConstants'
import { todoService } from '../../../helpers/todoService'
import { propertiesService } from '../../../helpers/propertiesService'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHook'
import { ErrorToast, SuccessToast } from '../../../helpers/toster'
import { setActivePageHeader } from '../../../store/auth/authSlice'
import { setSelectedTodos } from '../../../store/todo/todoSlice'
import DeleteConfirmModal from '../../../components/common/DeleteConfirm/DeleteConfirmModal'
import CommonAlertModal from '../../../components/common/CommonAlertModal/CommonAlertModal'
import DetailView from '../../../components/common/DetailView/DetailView'

import cartFilledIcon from '../../../assets/images/svgImages/cart_filled_icon.svg'

interface TodoTasksListProps {
  getTodoTasksData: () => void
}

const ToDoTasksList = (props: TodoTasksListProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { todotaskList, totalTodoTasksCount } = useAppSelector((state) => state.todoReducer)

  const [rowsToShow, setRowsToShow] = useState(todotaskList.slice(0, 8))
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(8)
  const [currentPage, setCurrentPage] = useState(1)
  const [showDeleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [showOutOfNotificationsModal, setShowOutOfNotificationsModal] = useState(false)
  const [selectedTodoTaskId, setSelectedTodoTaskId] = useState<number | null>(null)
  const [selectedProperty, setSelectedProperty] = useState<PropertyUnitDetail>()
  const [toggleDetailAside, setToggleDetailAside] = useState(false)
  const [selectedTaskDetails, setSelectedTaskDetails] = useState<ToDoTasksListData>()
  const [notificationSent, setNotificationSent] = useState<number>(0)
  const [selectedTask, setSelectedTask] = useState<ToDoTasksListData>()
  const [showDetailsView, setShowDetailsView] = useState(false)

  const user = JSON.parse(localStorage.getItem('pmanagement.user') || '{}')

  const WS_URL = process.env.REACT_APP_WEBSOCKET + WS_URLS.ZERO_CREDITS + user.id + '/'
  const { lastMessage } = useWebSocket(WS_URL, {
    shouldReconnect: () => true,
  })

  useEffect(() => {
    dispatch(setActivePageHeader('To-Do'))
    setRowsToShow(todotaskList.slice(0, 8))
    getNotificationsSent()
  }, [])

  useEffect(() => {
    setRowsToShow(todotaskList.slice(0, 8))
  }, [todotaskList])

  useEffect(() => {
    getPropertyUnitDetail()
  }, [selectedTaskDetails])

  const getPropertyUnitDetail = async () => {
    if (selectedTaskDetails && selectedTaskDetails?.units) {
      try {
        const response = await propertiesService.getPropertyUnitById(
          selectedTaskDetails?.units[0].toString(),
        )
        setSelectedProperty(response)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.data?.detail) {
            ErrorToast(err.response?.data?.detail || 'Error in delete images')
          } else {
            ErrorToast('Error in delete images')
          }
        }
      }
    }
  }

  const onPageChange = (e: PaginatorPageChangeEvent) => {
    setFirst(e.first)
    setRows(e.rows)
    setCurrentPage(e.page + 1)
    setRowsToShow(todotaskList.slice(e.first, e.rows * (e.page + 1)))
  }

  const handleRowSelect = (rowData: ToDoTasksListData) => {
    dispatch(setSelectedTodos(rowData))
    dispatch(setActivePageHeader('Task Detail'))
    setToggleDetailAside(!toggleDetailAside)
    setSelectedTaskDetails(rowData)
  }

  const handleDeleteTodoTask = async () => {
    try {
      await todoService.deleteToDoTask(selectedTodoTaskId)
      props.getTodoTasksData()
    } catch (err) {
      console.log(err)
    }
  }

  const handleResumeTask = async (id: number) => {
    try {
      await todoService.ResumeTask(id)
      props.getTodoTasksData()
      SuccessToast('Task status has been updated!')
    } catch (err) {
      if (isAxiosError(err))
        ErrorToast(err?.response?.data?.message)
      else
        ErrorToast('Error trying to pause task')
    }
  }

  const handlePauseTask = async (id: number) => {
    try {
      await todoService.PauseTask(id)
      props.getTodoTasksData()
      SuccessToast('Task status has been updated!')
    } catch (err) {
      if (isAxiosError(err))
        ErrorToast(err?.response?.data?.message)
      else
        ErrorToast('Error trying to pause task')
    }
  }

  const getNotificationsSent = async () => {
    const response = await todoService.notificationsSent()
    setNotificationSent(response.notifications_sent_current_month)
  }

  function removeDuplicates(array: any[]) {
    return array.filter((item, index) => array.indexOf(item) === index);
  }

  const processDate = (date: any) => {
    return moment(date).format('MMM D, YYYY')
  }

  /* Templates */

  const paginationTemplate = {
    layout: 'PrevPageLink PageLinks NextPageLink',
  }

  const dropDownTemplate = (rowData: ToDoTasksListData) => {
    const dropdownRef = useRef<any>(null)
    const dropdownItems = [
      { text: 'Open details', onClick: (e: any) => { dropdownRef.current.toggle(e); setSelectedTask(rowData); setSelectedTodoTaskId(rowData.id); setShowDetailsView(true) } },
      { text: 'Edit task', onClick: (e: any) => { dropdownRef.current.toggle(e); navigate(`${ROUTES.EDIT_TODO}/${rowData.id}`) } },
      { text: rowData.status === 'a' ? 'Pause task' : 'Resume task', onClick: (e: any) => { dropdownRef.current.toggle(e); rowData.status === 'a' ? handlePauseTask(rowData.id) : handleResumeTask(rowData.id) } },
      { text: 'Delete task', className: "text-danger", onClick: (e: any) => { dropdownRef.current.toggle(e); setDeleteConfirmModal(true); setSelectedTodoTaskId(rowData.id) } },
    ]

    return (
      <Dropdown ref={dropdownRef} onClick={(e: any) => dropdownRef.current?.toggle(e)} content={dropdownItems} />
    )
  }

  const endDateTemplate = (rowData: ToDoTasksListData) => {
    return rowData.schedule.ends_on_date ? processDate(rowData.schedule.ends_on_date) : ''
  }

  const creationDateTemplate = (rowData: ToDoTasksListData) => {
    return processDate(rowData.created)
  }

  const scheduleTemplate = (rowData: ToDoTasksListData) => {
    const schedule = rowData.schedule
    let frequency = ''
    switch (schedule.repeat_type) {
      case 'd':
        frequency = `Every ${schedule.repeat_frequency != '1' ? schedule.repeat_frequency + ' days' : 'day'
          }`
        break
      case 'm':
        frequency = `Every ${schedule.repeat_frequency != '1' ? schedule.repeat_frequency + ' months' : 'month'
          } on day ${schedule.repeat_on_day}`
        break
      case 'w':
        {
          const days = schedule.week_days
            ?.map((day) => WeekDaysValuesNames.find((x) => x.value == day)?.label)
            .join(' and ')
          frequency = `Every ${schedule.repeat_frequency != '1' ? schedule.repeat_frequency + ' weeks' : 'week'
            } on ${days}`
        }
        break
      case '2w':
        {
          const days = schedule.week_days
            ?.map((day) => WeekDaysValuesNames.find((x) => x.value == day)?.label)
            .join(' and ')
          frequency = `Every two weeks on ${days}`
        }
        break
      case 'y':
        frequency = `Every year`
        break
      default:
        break
    }
    return frequency
  }

  const recipientTemplate = (rowData: ToDoTasksListData) => {
    const recipient = rowData.notification_recipient
    switch (recipient) {
      case 'm':
        return 'Me'
      case 't':
        return 'Tenant'
      case 'mt':
        return 'Me and tenant'
      default:
        return ''
    }
  }

  const notificationTypeTemplate = (rowData: ToDoTasksListData) => {
    const recipient = rowData.notification_means
    switch (recipient) {
      case 'e':
        return 'Email'
      case 's':
        return 'SMS'
      case 'b':
        return 'Email and SMS'
      default:
        return ''
    }
  }

  const propertiesTemplate = (rowData: ToDoTasksListData) => {
    const properties = rowData.properties_title.split(', ')
    const uniquePropertiesValues = removeDuplicates(properties);
    return (
      <div>
        {
          uniquePropertiesValues.splice(0, 4).map((prop, index) => <Badge
            className='status-badge badge-list me-1 mb-1'
            value={prop ? prop : '--'}
            key={index}
          />)
        }
        {uniquePropertiesValues.length > 5 && <Badge
          className='status-badge badge-list me-1 mb-1'
          value={`+ ${uniquePropertiesValues.length - 5} more`}
        />}
      </div>
    )
  }

  const unitsTemplate = (rowData: ToDoTasksListData) => {
    const properties = rowData.units_title.split(', ')
    const uniquePropertiesValues = removeDuplicates(properties);
    return (
      <div>
        {
          uniquePropertiesValues.splice(0, 4).map((prop, index) => <Badge
            className='status-badge badge-list me-1 mb-1'
            value={prop ? prop : '--'}
            key={index}
          />)
        }
        {uniquePropertiesValues.length > 5 && <Badge
          className='status-badge badge-list me-1 mb-1'
          value={`+ ${uniquePropertiesValues.length - 5} more`}
        />}
      </div>
    )
  }

  const descriptionTemplate = (rowData: ToDoTasksListData) => {
    return (
      <div>{`${rowData.description.slice(0, 20)}${rowData.description.length > 20 ? '...' : ''
        }`}</div>
    )
  }

  const statusTemplate = (rowData: ToDoTasksListData) => {
    return (
      <Badge
        className={classNames({
          'status-badge badge-success': rowData.status === 'a',
          'status-badge badge-waning': rowData?.status === 'p',
          'status-badge badge-danger': rowData?.status === 's',

        })}
        severity={rowData.status === 'a' ? 'success' : rowData.status === 'p' ? 'warning' : 'danger'}
        value={rowData.status === 'a' ? 'Active' : rowData.status === 'p' ? 'Paused' : 'Stopped'}
      />
    )
  }

  return (
    <>
      <DetailView closeButton={showDetailsView} onCloseView={(e: boolean) => { setShowDetailsView(e) }}
        editButton={() => navigate(`${ROUTES.EDIT_TODO}/${selectedTodoTaskId}`)}
        deteleButton={() => setDeleteConfirmModal(true)}
      >{
          selectedTask &&
          <>
            <h2>{selectedTask?.name}</h2>
            <Container>
              <Row className='mb-2'>
                <Col sm={6}><h4>Recipient</h4></Col>
                <Col sm={6}>{recipientTemplate(selectedTask)}</Col>
              </Row>
              <Row className='mb-4'>
                <Col sm={6}><h4>Properties</h4></Col>
                <Col sm={6}>{propertiesTemplate(selectedTask)}</Col>
              </Row>
              <Row className='mb-4'>
                <Col sm={6}><h4>Units</h4></Col>
                <Col sm={6}>{unitsTemplate(selectedTask)}</Col>
              </Row>
              <Row className='mb-2'>
                <Col sm={6}><h4>Status</h4></Col>
                <Col sm={6}>{statusTemplate(selectedTask)}</Col>
              </Row>
            </Container>
            <hr />
            <Container>
              <h3>Notification details</h3>
              <Row className='mb-2'>
                <Col sm={6}><h4>Notification type</h4></Col>
                <Col sm={6}>{notificationTypeTemplate(selectedTask)}</Col>
              </Row>
              <Row className='mb-2'>
                <Col sm={6}><h4>Schedule</h4></Col>
                <Col sm={6}>{scheduleTemplate(selectedTask)}</Col>
              </Row>
              <Row className='mb-2'>
                <Col sm={6}><h4>Notification end date</h4></Col>
                <Col sm={6}>{endDateTemplate(selectedTask)}</Col>
              </Row>
              <Button className='btn simple' onClick={() => { selectedTask.status === 'a' ? handlePauseTask(selectedTask.id) : handleResumeTask(selectedTask.id); setShowDetailsView(false) }}>{selectedTask.status === 'a' ? 'Pause ' : 'Resume '} notification</Button>

            </Container>
          </>
        }
      </DetailView>
      <div className='data-list datatable-listing pb-2 mb-3'>
        <DataTable
          editMode='row'
          value={rowsToShow}
          dataKey='id'
          tableStyle={{ minWidth: '50rem' }}
        >
          <Column
            sortable
            className='fw-semibold text-black'
            field='name'
            header='Name'
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='notification_recipient'
            header='Recipient'
            body={recipientTemplate}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='properties_title'
            header='Properties'
            body={propertiesTemplate}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='units_title'
            header='Units'
            body={unitsTemplate}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='description'
            header='Description'
            body={descriptionTemplate}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='status'
            header='Status'
            body={statusTemplate}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='notification_means'
            header='Notification type'
            body={notificationTypeTemplate}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='schedule'
            header='Schedule'
            body={scheduleTemplate}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='schedule.ends_on_date'
            header='Notification end date'
            body={endDateTemplate}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='created'
            header='Creation date'
            body={creationDateTemplate}
          />
          <Column headerStyle={{ width: '4rem' }} body={dropDownTemplate} />
        </DataTable>
        <div className='pagination-section mt-3 d-flex align-items-center'>
          <div className='total-records'>
            Showing {first + 1}-
            {totalTodoTasksCount - first < rows
              ? first + (totalTodoTasksCount - first)
              : rows * currentPage}{' '}
            of {totalTodoTasksCount}
          </div>
          {todotaskList.length > 8 && (
            <div className='pagination-container ms-auto'>
              <Paginator
                template={paginationTemplate}
                first={first}
                rows={rows}
                totalRecords={totalTodoTasksCount}
                onPageChange={(e) => onPageChange(e)}
                className='justify-content-start'
              />
            </div>
          )}
        </div>
      </div>

      <DeleteConfirmModal
        handleReject={() => {
          setDeleteConfirmModal(false)
          setSelectedTodoTaskId(null)
        }}
        handleAccept={() => { handleDeleteTodoTask(); setDeleteConfirmModal(false) }}
        isModalOpen={showDeleteConfirmModal}
        header={'Delete task?'}
        message={'This action cannot be undone, and the tasks will not be recoverable.'}

      />

      {showOutOfNotificationsModal && (
        <CommonAlertModal
          isModalOpen={showOutOfNotificationsModal}
          handleClose={() => setShowOutOfNotificationsModal(false)}
          message='You’ve run out of paid notifications'
          subHeading='Notification sending for all tasks has been suspended. Purchase notifications to resume sending.'
          bottonText='Buy notifications'
          modalIcon={cartFilledIcon}
          handleButtonAction={() => {
            navigate(ROUTES.BUY_NOTIFICATIONS)
            setShowOutOfNotificationsModal(false)
          }}
        />
      )}
    </>
  )
}

export default ToDoTasksList
