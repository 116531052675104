import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from "axios";

import { useAppDispatch } from "../../../hooks/storeHook";
import { setActivePageHeader } from "../../../store/auth/authSlice";
import { setConnectedAccountsDetails } from "../../../store/setting/settingSlice";
import { ROUTES } from "../../../constants/routesConstant";
import { API_URLS } from "../../../constants/appConstants";
import { propertiesService } from "../../../helpers/propertiesService";
import { ErrorToast, SuccessToast } from "../../../helpers/toster";
import { settingService } from "../../../helpers/settingService";
import { PropertiesTypes, AddNewPropertyFormStates, Images, AddNewAdvantageProperty, statesDropdownStructure } from "../../../types/properties";
import { AccountDetail } from "../../../types/setting";

import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import UploadImages from "../../../components/common/UploadImages/UploadImages";
import NewAdvantageModal from "../../../components/common/NewAdvantageModal/NewAdvantageModal";

import trashIcon from "../../../assets/images/svgImages/trash.svg";


const initialValues: AddNewPropertyFormStates = {
    title: '',
    address: '',
    city: '',
    zip_code: '',
    features: [],
    description: '',
    type: '',
    sub_type: '',
    bank_account: '',
    state: ''
}

const NewProperties = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { propertyId } = useParams();
    const [propertyTypes, setPropertyTypes] = useState<PropertiesTypes[]>([]);
    const [propertySubTypes, setPropertySybTypes] = useState<PropertiesTypes[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [isEditMode] = useState(propertyId ? true : false);
    const [formInitialValues, setFormInitialValues] = useState(initialValues);
    const [propertyImagesData, setPropertyImagesData] = useState<Images[]>([]);
    const [states, setStates] = useState<statesDropdownStructure[]>([]);
    const [showAddAdvantageModal, setShowAddAdvantageModal] = useState(false);

    useEffect(() => {
        dispatch(setActivePageHeader(isEditMode ? 'Edit Property' : 'New Property'));
        getPropertyTypeData();
        getConnectedAccounts();
        getStatesData();
    }, []);

    useEffect(() => {
        if (isEditMode) {
            processEditFormData();
        }
    }, []);

    const addNewPropertyValidationSchema = () =>
        Yup.object().shape({
            title: Yup.string().required('Title is required*'),
            address: Yup.string().required('Address is required*'),
            city: Yup.string().required('City is required*'),
            state: Yup.string().required('State is required*'),
            zip_code: Yup.string().required('Zip Code is required*'),
            type: Yup.number().required('Property Type is required*'),
            sub_type: Yup.number().required('Property Sub Type is required*'),
            features: Yup.array()
                .min(1, "Please add min one features.")
                .nullable(),
            description: Yup.string().required('Description is required*'),
        })

    async function getConnectedAccounts() {
        try {
            const { accounts } = await settingService.getConnectedBankAccounts()
            dispatch(setConnectedAccountsDetails(accounts as AccountDetail[]))
        } catch (err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.data?.detail) {
                    ErrorToast(err.response?.data?.detail || 'Error in get account details.')
                } else {
                    ErrorToast('Error in get account details.')
                }
            }
        }
    }

    const getPropertyTypeData = async () => {
        const response = await propertiesService.getPropertyTypes();
        setPropertyTypes(response.results);
        getPropertySubTypeData();
    }

    const getPropertySubTypeData = async () => {
        const response = await propertiesService.getPropertySubTypes();
        setPropertySybTypes(response.results);
        if (isEditMode) {
            processEditFormData();
        }
    }

    const getStatesData = async () => {
        const response = await propertiesService.getStates();
        if (axios.isAxiosError(response)) {
            if (response.response?.data?.detail) {
                ErrorToast(response.response?.data?.detail || "Error in get states")
            } else {
                ErrorToast("Error in get states");
            }
        } else {
            setStates(response);
        }
    }

    const processEditFormData = async () => {
        const response = await propertiesService.getPropertyById(propertyId);
        if (axios.isAxiosError(response)) {
            if (response.response?.data?.detail) {
                ErrorToast(response.response?.data?.detail || "Error in get property")
            } else {
                ErrorToast("Error in get property");
            }
        } else {
            const selectedPropertyData = response;
            setPropertyImagesData(selectedPropertyData.images);
            setFormInitialValues({
                title: selectedPropertyData.title,
                address: selectedPropertyData.address,
                city: selectedPropertyData.city,
                zip_code: selectedPropertyData.zip_code,
                features: selectedPropertyData.features,
                description: selectedPropertyData.description,
                type: selectedPropertyData.type,
                sub_type: selectedPropertyData.sub_type,
                bank_account: selectedPropertyData.bank_account ? selectedPropertyData.bank_account : '',
                state: selectedPropertyData.state
            })
        }
    }

    // eslint-disable-next-line
    const handleSubmit = async (formValues: any) => {
        if (!isEditMode && files.length === 0) {
            ErrorToast("Please upload property image")
            return false;
        }
        const formdata = new FormData();
        const objKeys = Object.keys(formValues) as Array<keyof AddNewPropertyFormStates>
        objKeys.forEach((key) => {
            if (Array.isArray(formValues[key as keyof AddNewPropertyFormStates])) {
                formValues[key as keyof AddNewPropertyFormStates].forEach((arr_item: string) => {
                    formdata.append(key, arr_item);
                })
            } else {
                formdata.append(key, formValues[key]);
            }
        });
        files.forEach((_file, index) => {
            formdata.append(`images[${index}]image`, files[index]);
        });
        try {
            if (isEditMode) {
                await propertiesService.editProperty(formdata, propertyId);
            } else {
                await propertiesService.saveProperty(formdata);
            }
            navigate(ROUTES.PROPERTIES);
        } catch (err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.data?.detail) {
                    if (isEditMode) {
                        ErrorToast(err.response?.data?.detail || "Error in add new property")
                    } else {
                        ErrorToast(err.response?.data?.detail || "Error in edit property")
                    }
                } else {
                    ErrorToast(isEditMode ? "Error in edit property" : "Error in add new property");
                }
            }
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const filesData = target.files ? Array.from(target.files) : [];
        if (filesData.length) {
            let uploadFiles = files.slice();
            uploadFiles = uploadFiles.concat(filesData);
            if ((uploadFiles.length + propertyImagesData.length) > 5) {
                ErrorToast("Maximum 5 files can be uploaded");
                return false;
            } else {
                setFiles(uploadFiles);
            }
        }
    }

    const handleFileDelete = (index: number) => {
        const uploadFiles = files.slice();
        uploadFiles.splice(index, 1);
        setFiles(uploadFiles);
    }

    const handleSavedFileDelete = async (imgId: number) => {
        let savedImages = propertyImagesData.slice();
        savedImages = savedImages.filter(data => data.id !== imgId);
        try {
            const url = API_URLS.PROPERTY_DELETE_IMAGE.replace('{id}', imgId.toString())
            await propertiesService.deleteSavedUploadedImage(url);
            setPropertyImagesData(savedImages);
            SuccessToast("Image deleted successfully");
        } catch (err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.data?.detail) {
                    ErrorToast(err.response?.data?.detail || "Error in delete images")
                } else {
                    ErrorToast("Error in delete images");
                }
            }
        }
    }

    return (
        <>
            <div className="new-property col-12 col-sm-10">
                <h2 className="title mb-5">{isEditMode ? "Edit a property" : "Create a property"}</h2>
                <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    onSubmit={(values: AddNewPropertyFormStates) => handleSubmit(values)}
                    validationSchema={() => addNewPropertyValidationSchema()}
                    validateOnChange={true}
                    validateOnBlur={true}
                >
                    {({
                        errors,
                        values,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        dirty,
                        isValid
                    }) => (
                        <Form>
                            <fieldset>
                                <legend>Main information</legend>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup className="mb-4">
                                            <Label>
                                                Name<span className="required">*</span>
                                            </Label>
                                            <Input
                                                id="title"
                                                name="title"
                                                placeholder="Enter Title of the Property"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.title}
                                                required
                                            />
                                            {touched.title && errors.title && (
                                                <span className='error-text text-danger'>{errors.title}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                Type<span className="required">*</span>
                                            </Label>
                                            <Input
                                                id="property-type"
                                                name="type"
                                                type="select"
                                                bsSize="lg"
                                                value={values.type}
                                                onBlur={handleBlur}
                                                onChange={(e) => setFieldValue("type", e.target.value)}
                                                required
                                            >
                                                <>
                                                    <option disabled value={""}>Choose Your Property Type</option>
                                                    {propertyTypes.map((propertyType, index) => <option key={index} value={propertyType.id}>{propertyType.title}</option>)}
                                                </>

                                            </Input>
                                            {touched.type && errors.type && (
                                                <span className='error-text text-danger'>{errors.type}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                Subtype<span className="required">*</span>
                                            </Label>
                                            <Input
                                                id="property"
                                                name="sub_type"
                                                type="select"
                                                bsSize="lg"
                                                onBlur={handleBlur}
                                                value={values.sub_type}
                                                onChange={(e) => setFieldValue("sub_type", e.target.value)}
                                                required
                                            >
                                                <option disabled value={""}> Choose Your Accomodation</option>
                                                {propertySubTypes.map((propertySubType, index) => <option key={index} value={propertySubType.id}>{propertySubType.title}</option>)}
                                            </Input>
                                            {touched.sub_type && errors.sub_type && (
                                                <span className='error-text text-danger'>{errors.sub_type}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </fieldset>
                            <fieldset>
                                <legend>Address</legend>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                Street address<span className="required">*</span>
                                            </Label>
                                            <Input
                                                id="address"
                                                name="address"
                                                placeholder="Add Your Address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.address}
                                                required
                                            />
                                            {touched.address && errors.address && (
                                                <span className='error-text text-danger'>{errors.address}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                City<span className="required">*</span>
                                            </Label>
                                            <Input
                                                id="city"
                                                name="city"
                                                placeholder="Add City"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.city}
                                                required
                                            />
                                            {touched.city && errors.city && (
                                                <span className='error-text text-danger'>{errors.city}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                State<span className="required">*</span>
                                            </Label>
                                            <Input
                                                id="state"
                                                name="state"
                                                type="select"
                                                bsSize="lg"
                                                onBlur={handleBlur}
                                                value={values.state}
                                                required
                                                onChange={(e) => setFieldValue("state", e.target.value)}
                                            >
                                                <option disabled value={""}> Choose Your Accomodation</option>
                                                {states.map((state, index) => (<option key={index} value={state.short_name}>{state.full_name}</option>))}
                                            </Input>
                                            {touched.state && errors.state && (
                                                <span className='error-text text-danger'>{errors.state}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                ZIP / Postal code<span className="required">*</span>
                                            </Label>
                                            <Input
                                                id="zipCode"
                                                name="zip_code"
                                                placeholder="Add Zip Code"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.zip_code}
                                                required
                                            />
                                            {touched.zip_code && errors.zip_code && (
                                                <span className='error-text text-danger'>{errors.zip_code}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </fieldset>
                            <fieldset>
                                <legend>Details</legend>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <Label className="mb-0">
                                                Features<span className="required">*</span>
                                            </Label>
                                            <Button className="btn flat" onClick={() => setShowAddAdvantageModal(true)} >Add feature</Button>
                                        </FormGroup>
                                    </Col>
                                    <div className="features-items">
                                        {values.features.length ? values.features.map((feature, index) => (
                                            <div className="feature-card" key={index}>
                                                <span className="feature-text">{feature}</span>
                                                <img src={trashIcon} alt="trash icon" onClick={() => { values.features.splice(index, 1); setFieldValue("features", values.features) }} />
                                            </div>
                                        )) : ''}
                                    </div>
                                    {touched.features && errors.features && (
                                        <span className='error-text text-danger'>{errors.features}</span>
                                    )}
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Label>
                                            Description<span className="required">*</span>
                                        </Label>
                                        <Input
                                            id="description"
                                            name="description"
                                            type="textarea"
                                            placeholder="Enter a property description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                            maxLength={400}
                                        />
                                        {touched.description && errors.description ? (
                                            <span className='error-text text-danger'>{errors.description}</span>
                                        ) : (
                                            <span className="input-group-caption">Maximum of 400 characters</span>
                                        )}
                                    </Col>
                                </Row>
                            </fieldset>
                            <FormGroup className="upload-image-section pt-2">
                                <Label>
                                    Upload Images
                                </Label>
                                <UploadImages files={files} handleFileChange={handleFileChange} handleFileDelete={handleFileDelete} imagesData={propertyImagesData} isEditFormMode={isEditMode ? true : false} handleSavedFileDelete={handleSavedFileDelete} />
                            </FormGroup>
                            <div className="submit-wrapper">
                                <Button type="submit" className="btn cta" disabled={!dirty || !isValid}>{isEditMode ? "Edit property" : "Create property"}</Button>
                                <Button className="btn simple" onClick={() => navigate(ROUTES.PROPERTIES)}>Cancel</Button>
                            </div>


                            <NewAdvantageModal
                                isModalOpen={showAddAdvantageModal}
                                handleClose={(e?: AddNewAdvantageProperty) => {
                                    if (e?.name) {
                                        const featuresData: string[] = values.features.slice();
                                        featuresData.push(e.name);
                                        setFieldValue("features", featuresData);
                                        SuccessToast("property feature created")
                                    }
                                    setShowAddAdvantageModal(false);
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </div >


        </>
    )
}

export default NewProperties;