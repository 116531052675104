import { useEffect, useState } from 'react'
import { Button, Card, CardBody } from 'reactstrap'

import { setActivePageHeader } from '../../../../store/auth/authSlice'
import { useAppDispatch } from '../../../../hooks/storeHook'
import cartIcon from '../../../../assets/images/svgImages/cart_icon.svg'
import starIcon from '../../../../assets/images/svgImages/star_icon.svg'
import OrderSummary from '../OrderSummary/OrderSummary'
import { propertiesService } from '../../../../helpers/propertiesService'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../constants/routesConstant'

const Subscriptions = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [planType, setPlanType] = useState('custom-plan')
  const [unitQuantity, setUnitQuantity] = useState(1)
  const [unitYearlyPrice, setUnitYearlyPrice] = useState(0)
  const [unitMonthlyPrice, setUnitMonthlyPrice] = useState(0)
  const [unitPrice, setUnitPrice] = useState(0)
  const [yearlyOffPercent, setYearlyOffPercent] = useState(0)
  const [isCheckoutClick, setIsCheckoutClick] = useState(false)
  const [planBillType, setPlanBillType] = useState('Y')

  useEffect(() => {
    dispatch(setActivePageHeader('Subscriptions'))
    getSubscriptionsData()
    // getPaidConnectsData();
    getUnitPriceData()
  }, [])

  const handleBillChange = (billSelectionType: string) => {
    setPlanBillType(billSelectionType)
    if (billSelectionType === 'M') {
      setUnitPrice(unitMonthlyPrice)
    } else {
      setUnitPrice(unitYearlyPrice)
    }
  }

  const handlePlanChange = (planType: string) => {
    setPlanType(planType)
  }

  const handleCheckout = () => {
    setIsCheckoutClick(true)
  }

  const getSubscriptionsData = async () => {
    const response = await propertiesService.getSubscriptions()
    if (response && response.length > 0) {
      setPlanType('custom-plan')
      setUnitPrice(unitYearlyPrice)
    }
  }

  const getUnitPriceData = async () => {
    const response = await propertiesService.getUnitPrice()
    setUnitYearlyPrice(response?.yearly_amount)
    setUnitMonthlyPrice(response?.monthly_amount)
    setUnitPrice(response?.yearly_amount)
    setYearlyOffPercent(response?.yearly_off_percent)
  }

  // const getPaidConnectsData = async () => {
  //     const response = await propertiesService.getPaidConnects();
  //     setUnitQuantity(response?.total_paid_connects || 2);
  // }

  return (
    <>
      {!isCheckoutClick ? (
        <Card className='propertyM-card subscription-wrapper'>
          <CardBody className='text-center'>
            <div className='d-flex align-items-center justify-content-center flex-column'>
              <div className='cart-icon d-flex align-items-center justify-content-center'>
                <img src={cartIcon} />
              </div>
              <div className='subscription-card-heading mt-2 mb-1'>Choose pricing plan</div>
              <div className='subscription-card-sub-heading'>No suprise fees. Cancel anytime</div>
              <div className='subscription-buttons-wrapper d-flex align-items-center my-4'>
                <Button
                  className={planBillType === 'M' ? 'subscription-btn active' : 'subscription-btn'}
                  onClick={() => handleBillChange('M')}
                >
                  Monthly
                </Button>

                <Button
                  className={planBillType === 'Y' ? 'subscription-btn active' : 'subscription-btn'}
                  onClick={() => handleBillChange('Y')}
                >
                  Annually <span className='discount-badge ms-1'>-{yearlyOffPercent}%</span>
                </Button>
              </div>

              <div className='row justify-content-center px-0 py-3 px-md-5 w-100'>
                {/* <div className={planType === 'starter-plan' ? 'selected subscription-plan-card cursor-pointer w-100 ms-md-5' : 'subscription-plan-card cursor-pointer w-100 ms-md-5' } onClick={() => handlePlanChange("starter-plan")}>
                                    <div className="plan-description">
                                        <div className="d-flex align-items-center">
                                            <div className="plan-name">Starter Plan</div>
                                            <div className="star-icon ms-auto">
                                                <img src={starIcon} />
                                            </div>
                                        </div>
                                        <div className="d-flex d-flex align-items-center mt-2">
                                            <div className="plan-price">$0</div>
                                            <div className="unit">/Unit</div>
                                        </div>
                                    </div>
                                    <div className="plan-info d-flex justify-content-center flex-column w-100">
                                        <div className="plan-include">Starter Plan Include 1 unit Free </div>
                                        <div className="mt-1 plan-charges">After 1 unit {unitPrice}$/ Unit will be charged per month</div>
                                        <div className="cursor-pointer plan-type mt-4 w-100">Free Lifetime Plan</div>
                                    </div>   
                                </div> */}
                <div
                  className={
                    planType === 'custom-plan'
                      ? 'col-md-8 col-lg-5 selected subscription-plan-card cursor-pointer'
                      : 'col-md-8 col-lg-6 subscription-plan-card cursor-pointer ms-md-5'
                  }
                  onClick={() => handlePlanChange('custom-plan')}
                >
                  <div className='plan-description'>
                    <div className='d-flex align-items-center'>
                      <div className='plan-name'>Unit Connects</div>
                      <div className='star-icon ms-auto d-flex align-items-center'>
                        <img src={starIcon} />
                        <img className='ms-2' src={starIcon} />
                        <img className='ms-2' src={starIcon} />
                      </div>
                    </div>
                    <div className='d-flex align-items-center mt-2'>
                      <div className='plan-price'>${unitPrice}</div>
                      <div className='unit'>/Unit</div>
                    </div>
                  </div>
                  <div className='plan-info d-flex justify-content-center flex-column w-100'>
                    <div className='plan-include'>Quantity of property units </div>
                    <div className='mt-1 plan-charges'>
                      Choose the quantity of units you want to add
                    </div>
                    <div className='cursor-pointer plan-type mt-4 w-100'>
                      <div className='d-flex align-items-center justify-content-center'>
                        <Button
                          className='quantity-btn'
                          onClick={() => {
                            if (unitQuantity > 1) {
                              setUnitQuantity(unitQuantity - 1)
                            }
                          }}
                        >
                          -
                        </Button>
                        <div className='mx-3 quantity'>{unitQuantity}</div>
                        <Button
                          className='quantity-btn'
                          onClick={() => {
                            setUnitQuantity(unitQuantity + 1)
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-end pt-5 w-100 pe-4'>
                <div className='p-2'>
                  <Button
                    className='btn outline-cancel-btn'
                    onClick={() => navigate(ROUTES.LANDLORD_SETTINGS)}
                  >
                    Cancel
                  </Button>
                </div>
                <div className='p-2'>
                  <Button
                    className='btn submit-btn'
                    disabled={planType === 'starter-plan'}
                    onClick={handleCheckout}
                  >
                    Checkout
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      ) : (
        <OrderSummary
          unitQuantity={unitQuantity}
          unitPrice={unitPrice}
          planBillType={planBillType}
        />
      )}
    </>
  )
}

export default Subscriptions
