import companyLogo from '../../assets/images/company-logo.png';

const CompanyInfo = () => {
    return (
        <div className="company-info">
            <div className="company-logo">
                <img src={companyLogo} />
            </div>
            <div className="tag-line mt-3">One Tool for All Your Properties</div>
            <div className="mt-4 company-content">Managing properties has become arguably the most time consuming or expensive effort in the rental property game. We&apos;ve built a tool to make your rental property effortless&lsquo; all under one roof!</div>
            <div className="d-flex align-items-center mt-4">
                <div className="people-joined-circles d-flex">
                    <div className="people-joined-circle"></div>
                    <div className="people-joined-circle"></div>
                    <div className="people-joined-circle"></div>
                </div>    
                <div className="people-joined">3k+ people joined us, now it&apos;s your turn</div>
            </div>
        </div>
    )
}

export default CompanyInfo;