import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row, FormGroup, Input, Label } from 'reactstrap';
import companyLogo from '../../assets/images/company-logo.png';
import { API_URLS } from '../../constants/appConstants';
import { ROUTES } from '../../constants/routesConstant';
import API from '../../helpers/api';
import { ErrorToast, SuccessToast } from '../../helpers/toster';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { setNewPassFormStates } from '../../types/auth';

import PrivacyTerms from '../../components/common/PrivacyTerms/component'

const initialValues = {
    pass: '',
    confirmPass: ''
}

const newPassSchema = () => Yup.object().shape({
    pass: Yup
        .string()
        .min(8, 'Password must be at least 8 charaters')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "Password must contain at least one letter, one number and one special character")
        .required('Password is required*'),
    confirmPass: Yup
        .string()
        .oneOf([Yup.ref('pass')], 'New Password and Confirm Password must match')
        .required('Confirm Password is required*'),
});

const SetNewPassword = () => {
    const navigate = useNavigate();
    const location = useLocation().search;
    const queryParams = new URLSearchParams(location);
    const token = queryParams.get('token');
    const uidb64 = queryParams.get('uidb64');

    const handleResetPassword = async (values: setNewPassFormStates) => {
        const { pass, confirmPass } = values
        if (pass.trim().toLowerCase() !== confirmPass.trim().toLowerCase()) {
            ErrorToast("New Password and Confirm Password didn't match");
            return false;
        }
        const resetPasswordData = {
            token,
            uidb64,
            password: pass,
            confirm_password: confirmPass
        }
        try {
            const response = await API.patch(API_URLS.SET_NEW_PASSWORD, resetPasswordData);
            if (response.status === 201 || response.status === 200) {
                navigate(ROUTES.LOGIN);
                SuccessToast("New Password created successfully. Please login");
            } else {
                ErrorToast("Error in email verification");
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.data?.detail) {
                    ErrorToast(error.response?.data?.detail);
                    return false;
                }
            }
            ErrorToast("Token expired");
        }
    }

    return (
        <div className="container-fluid password-reset">
            <Row className="h-100">
                <Col className="password-page-companyinfo" md={4}>
                    <div className="company-logo">
                        <img src={companyLogo} />
                    </div>
                    <div className="multipurpose-tool-text mt-3">Multipurpose Tool to Succeed Your Property Business</div>
                    <div className="having-trouble">Having troubles? <span className="get-help">Get Help</span></div>
                </Col>
                <Col md={8} className="d-flex justify-content-center align-items-center position-relative flex-column">
                    <div className="form-wrapper">
                        <div className="form-card-container pt-2">
                            <div className="form-card-heading">Create new password</div>
                            <div className="sub-heading pt-2">Please create a new password that you don’t use on any other site.</div>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values: setNewPassFormStates) => handleResetPassword(values)}
                                validationSchema={() => newPassSchema()}>
                                {({
                                    errors,
                                    values,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    dirty,
                                    isValid
                                }) => (
                                    <Form>
                                        <FormGroup className="mb-4 mt-4">
                                            <div className="boxlabel-input-container">
                                                <Label for="email">Create new password</Label>
                                                <Input type="password" name="pass" value={values.pass} placeholder="Create new password" onChange={handleChange} onBlur={handleBlur} required />
                                            </div>
                                            {touched.pass && errors.pass && (
                                                <span className='error-text text-danger'>{errors.pass}</span>
                                            )}
                                        </FormGroup>
                                        <FormGroup className="mb-4">
                                            <div className="boxlabel-input-container">
                                                <Label for="email">Confirm new password</Label>
                                                <Input type="password" name="confirmPass" value={values.confirmPass} placeholder="Confirm new password" onChange={handleChange} onBlur={handleBlur} required />
                                            </div>
                                            {touched.confirmPass && errors.confirmPass && (
                                                <span className='error-text text-danger'>{errors.confirmPass}</span>
                                            )}
                                        </FormGroup>
                                        <Button className="submit-btn" type="submit" block disabled={!dirty || !isValid}>Change Password</Button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <PrivacyTerms />
                </Col>
            </Row>
        </div>
    )
}

export default SetNewPassword;