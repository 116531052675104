import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../hooks/storeHook'
import { setActivePageHeader } from '../../../store/auth/authSlice'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Card, CardBody, Col, Row } from 'reactstrap'
import cartFilledIcon from '../../../assets/images/svgImages/cart_filled_icon.svg'
import inboxIcon from '../../../assets/images/svgImages/inbox_icon.svg'
import cardIcon from '../../../assets/images/card_icon.png'
import lockIcon from '../../../assets/images/svgImages/lock_icon.svg'
import {
  PerEmailPricing,
  PerSMSPricing,
  BuyNotificationsData,
  CardDetail,
  TotalPrice,
} from '../../../types/notifications'
import {
  NotificationCard,
  NotificationQuantityController,
} from '../../../components/common/NotificationCard/NotificationCard'
import { notificationService } from '../../../helpers/notificationsService'
import { propertiesService } from '../../../helpers/propertiesService'
import axios from 'axios'
import { ErrorToast, SuccessToast } from '../../../helpers/toster'
import CheckoutForm from '../../../components/CheckoutForm/CheckoutForm'
import { Elements, useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { getUser } from '../../../helpers/userService'
import { ROUTES } from '../../../constants/routesConstant'
import { number } from 'yup'

const initialCardDetails = {
  card_id: '',
  cc_last4: '',
  exp_month: '',
  exp_year: '',
  landlord: null,
}

const stripe = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY ? process.env.REACT_APP_STRIPE_API_KEY : '',
)

const BuyNotificationsComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const userData = getUser()
  const [plan, setPlan] = useState('')
  const [cardDetails, setCardDetails] = useState<CardDetail>(initialCardDetails)
  const [isPaymentLoading, setisPaymentLoading] = useState(false)
  const [perPriceSMS, setPerPriceSMS] = useState<PerSMSPricing>()
  const [perPriceEmail, setPerPriceEmail] = useState<PerEmailPricing>()
  const [currentEmails, setCurrentEmails] = useState<number>()
  const [currentSMS, setCurrentSMS] = useState<number>()
  const [totalAmount, setTotalAmount] = useState<TotalPrice | null>(null)

  useEffect(() => {
    switch (plan) {
      case 'plan-sms':
        setCurrentEmails(0)
        setCurrentSMS(10)
        break
      case 'plan-email':
        setCurrentSMS(0)
        setCurrentEmails(perPriceEmail?.number_of_emails || 0)
        break
      case 'plan-sms-email':
        setCurrentEmails(perPriceEmail?.number_of_emails || 0)
        setCurrentSMS(10)
        break
      default:
        setCurrentEmails(0)
        setCurrentSMS(0)
        break
    }
  }, [plan])

  useEffect(() => {
    dispatch(setActivePageHeader('Buy notifications'))
    getPerEmailPrice()
    getPerSMSPrice()
    getCreditCardData()
  }, [])

  useEffect(() => {
    getTotalAmount()
  }, [currentEmails, currentSMS])

  const handleSubmit = async () => {
    if (cardDetails.card_id !== '') {
      setisPaymentLoading(true)
      handleCheckout(cardDetails.card_id)
      return
    }
    if (!stripe && !elements) {
      return
    }
    const card = elements?.getElement(CardNumberElement)
    if (card) {
      setisPaymentLoading(true)
      try {
        const result = await stripe?.createToken(card)
        if (result?.error) {
          setisPaymentLoading(false)
          ErrorToast(result?.error?.message || 'Error in payment')
        } else {
          setisPaymentLoading(false)
          handleCheckout(result?.token?.id || '')
        }
      } catch (err) {
        setisPaymentLoading(false)
        ErrorToast('Error in payment')
      }
    }
  }

  const handleCheckout = async (token?: string) => {
    const formData: BuyNotificationsData = {
      cc_token: cardDetails.card_id !== '' ? undefined : token,
      sms_credits: currentSMS,
      email_credits: currentEmails,
    }
    try {
      await notificationService.buyNotifications(formData)
      SuccessToast('Payment Success')
      setisPaymentLoading(false)
      navigate(ROUTES.TODO)
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        setisPaymentLoading(false)
        if (err.response?.data) {
          ErrorToast(err.response?.data?.msg || 'Error in payment')
        } else {
          ErrorToast('Error in payment')
        }
      } else {
        setisPaymentLoading(false)
        ErrorToast(err.msg || 'Error in payment')
      }
    }
  }

  const getPerEmailPrice = async () => {
    try {
      const response = await notificationService.getPerEmailPrice()
      setPerPriceEmail(response)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data) {
          ErrorToast(
            err.response?.data || 'Something went wrong when trying to get the price per Email',
          )
        } else {
          ErrorToast('Something went wrong when trying to get the price per Email')
        }
      }
    }
  }
  const getPerSMSPrice = async () => {
    try {
      const response = await notificationService.getPerSMSPrice()
      setPerPriceSMS(response)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data) {
          ErrorToast(
            err.response?.data || 'Something went wrong when trying to get the price per SMS ',
          )
        } else {
          ErrorToast('Something went wrong when trying to get the price per SMS ')
        }
      }
    }
  }

  const getCreditCardData = async () => {
    const response = await propertiesService.getCreditCardDetail()
    if (response) {
      setCardDetails(response)
    }
  }

  const getTotalAmount = async () => {
    const data = {
      email_credits: currentEmails || 0,
      sms_credits: currentSMS || 0,
    }
    const response = (await notificationService.calculatePrice(data)) as TotalPrice
    if (response) {
      setTotalAmount(response)
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row className='buy-notifications'>
            <Col className='p-5'>
              <div className='detail'>
                <div className='title mb-4'>
                  <span>
                    <img src={cartFilledIcon} /> Checkout
                  </span>
                </div>
                <p>
                  Select the type and quantity of notifications you want to pay for to send
                  according to the tasks created.
                </p>
              </div>
              <div className='options'>
                <NotificationCard
                  title={'via SMS'}
                  price={perPriceSMS?.price}
                  description={
                    'For sending messages via SMS, this type of notification must be selected in the task details'
                  }
                  value={'sms'}
                  id={'plan-sms'}
                  onChecked={(e: any) => {
                    setPlan(e.target.id)
                  }}
                >
                  <>
                    <hr />
                    <NotificationQuantityController
                      initialValue={perPriceSMS?.number_of_sms || 1}
                      increaseValue={perPriceSMS?.number_of_sms || 1}
                      onChange={(value: number) => {
                        setCurrentSMS(value)
                      }}
                      text='Messages via SMS'
                    />
                  </>
                </NotificationCard>
                <NotificationCard
                  title={'via Email'}
                  price={perPriceEmail?.price}
                  description={
                    'For sending messages via Email, this type of notification must be selected in the task details.'
                  }
                  value={'email'}
                  id={'plan-email'}
                  onChecked={(e: any) => {
                    setPlan(e.target.id)
                  }}
                >
                  <>
                    <hr />
                    <NotificationQuantityController
                      initialValue={perPriceEmail?.number_of_emails || 1000}
                      increaseValue={perPriceEmail?.number_of_emails || 1000}
                      onChange={(value: number) => {
                        setCurrentEmails(value)
                      }}
                      text='Messages via email'
                    />
                  </>
                </NotificationCard>
                <NotificationCard
                  title={'via SMS and Email'}
                  description={
                    'You can choose any combination of notifications and then distribute them among tasks as desired.'
                  }
                  value={'sms-email'}
                  id={'plan-sms-email'}
                  onChecked={(e: any) => {
                    setPlan(e.target.id)
                  }}
                >
                  <>
                    <hr />
                    <NotificationQuantityController
                      initialValue={10}
                      increaseValue={10}
                      onChange={(value: number) => {
                        setCurrentSMS(value)
                      }}
                      text='Messages via SMS'
                    />
                    <br />
                    <NotificationQuantityController
                      initialValue={1000}
                      increaseValue={1000}
                      onChange={(value: number) => {
                        setCurrentEmails(value)
                      }}
                      text='Messages via email'
                    />
                  </>
                </NotificationCard>
              </div>
            </Col>
            <Col className='p-5'>
              <div className='card-information'>
                {cardDetails.card_id !== '' ? (
                  <div className='card-details-section mt-5'>
                    <div className='heading-text me-2'>Payment method</div>
                    <div className='sub-heading-text mb-3'>Change your payment method</div>
                    <div className='card-details w-100'>
                      <div className='d-flex align-items-start'>
                        <div className='px-2 py-1'>
                          <img src={cardIcon} />
                        </div>
                        <div className='mx-2 px-2 py-1'>
                          <div className='card-number'>
                            ****{'  '}****{'  '}****{'  '}
                            {cardDetails.cc_last4}
                          </div>
                          <div className='card-exp-date mt-1'>
                            {cardDetails.exp_month} / {cardDetails.exp_year}
                          </div>
                          <div className='card-exp-date mt-1'>
                            <img className='me-2' src={inboxIcon} />
                            {userData?.email}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='d-flex align-items-end mt-5'>
                      <div className='heading-text me-2'>Payment Info</div>
                      <div className='cursor-pointer'>
                        <img src={lockIcon} />
                      </div>
                    </div>
                    <CheckoutForm />
                  </>
                )}
              </div>
              <hr />
              <div className='mt-5'>
                <Col className='d-flex flex-row justify-content-between align-items-center'>
                  <span className='total-amount-text'>Total amount</span>
                  <span className='total-amount'>
                    $ {totalAmount ? totalAmount.total_price : 0.0}
                  </span>
                </Col>
                <Row>
                  <Button
                    loading={isPaymentLoading}
                    loadingIcon='pi pi-spin pi-sun'
                    type='submit'
                    className='btn cta'
                    onClick={handleSubmit}
                    disabled={currentEmails === 0 && currentSMS === 0 ? true : false}
                  >
                    Pay $ {totalAmount ? totalAmount?.total_price : 0.0}
                  </Button>
                </Row>
                <Row className='mt-3'>
                  <span className='payment-secure-text '>
                    <img className='me-1' width={14} height={14} src={lockIcon} />
                    Payments are secure
                  </span>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

const BuyNotifications = () => {
  return (
    <Elements stripe={stripe}>
      <BuyNotificationsComponent />
    </Elements>
  )
}

export default BuyNotifications
